import { useState } from 'react';
import { publish } from '../events';
import { onMessageListener} from './firebase';

const Notification = () => {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: "", body: "" });
    onMessageListener()
      .then((payload) => {
        setShow(true);
        publish('notification',{
          title: payload.notification.title,
          body: payload.notification.body,
          data: payload.data
        })
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      })
      .catch((err) => console.log("failed: ", err));
   

      return null
}

export default Notification