import React, { useEffect } from 'react'
import './PhotoGrid.css'
import { PostData } from './PostData.ts';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from './variables.ts';

const PhotoGrid: React.FC<{user_id: number}> = ({user_id}) => {
    const navigate = useNavigate()

    useEffect(() => {
        setImages()
    }, [])

    const setImages = async () =>{
        const gridContainer = document.getElementById('grid-container');
        const imagePosts = await PostData('getUserImages.php', {user_id}) as []
        if(!gridContainer) return
        gridContainer.innerHTML = '';
        imagePosts.forEach(function (post: any) {
            const gridItem = document.createElement('div');
            gridItem.classList.add('grid-item');
            const img = document.createElement('img');
            img.src = BASE_URL + post.postImage;
            img.onclick = function() {
                // Construir la URL del post utilizando el ID del post
                const postId = post.postID; // Asumiendo que 'id' es el campo que contiene el ID del post
                navigate('/post/' + postId)
            };
            gridItem.appendChild(img);
            gridContainer.appendChild(gridItem);
        });
    }

    return (
        <div className="photo-container">
            <div className="scrollable-container" id="scrollable-container">
                <div className="grid-container" id="grid-container"></div>
            </div>
        </div>
    )
}

export default PhotoGrid