import React, { useEffect, useState } from 'react'
import './EventButtons.css'
import { CheckIcon } from '@primer/octicons-react';
import { PostData } from './PostData.ts';
import { useSubmit } from 'react-router-dom';

type UserButtonsProps = {
    onPress: (op: number) => void;
    defaultValue?: number
}

const EventButtons: React.FC<{eventID: number}> = ({eventID}) => {
    const [op, setOp] = useState<'none' | 'no' | 'maybe' | 'yes'>('none')
    const  submit = useSubmit()

    useEffect(()=>{
        getEventStatus()
    },[])

    const getEventStatus = async () =>{
        const event_status = await PostData('getEventStatus.php', {eventID}) as any
        setOp(event_status.status)
    }

    const onClick = (selection: 'none' | 'no' | 'maybe' | 'yes') => {
        PostData('setEventStatus.php',{eventID, status: selection})
        setOp(selection)
        submit('post')
    }

    const Check = () => {
        return (
            <div style={{ background: 'linear-gradient(#72B250, #509D2A)', height: '25px', width: '25px', position: 'absolute', top: '-6px', right: '-2px', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 2px' }}>
                <CheckIcon fill='white' size={18} />
            </div>
        )
    }

    return (
        <div className='container-event-buttons'>
            <p style={{ fontSize: '1em', fontWeight: '600', margin:0, marginBottom: '4px' }}>¿Asistirás?</p>
            <div className='event-buttons-background'>
                <div onClick={() => onClick('no')} className={op === 'no' ? 'button left-button button-pressed' : 'button left-button'}>
                    {op === 'no' && <Check />}
                    <p>No</p>
                </div>
                <div onClick={() => onClick('maybe')} className={op === 'maybe' ? 'button button-pressed' : 'button'} >
                    {op === 'maybe' && <Check />}
                    <p>Quizás</p>
                </div>
                <div onClick={() => onClick('yes')} className={op === 'yes' ? 'button right-button button-pressed' : 'button right-button'}>
                    {op === 'yes' && <Check />}
                    <p>Si</p>
                </div>
            </div>
        </div>
    )
}

export default EventButtons;