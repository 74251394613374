import React, { useEffect } from 'react'
import { useNavigate, useSubmit } from 'react-router-dom';
import { PostsProps } from './Props';
import { ChevronRightIcon, TrashIcon } from '@primer/octicons-react';
import { timeDifference } from './utils.ts';
import { BASE_URL } from './variables.ts';
import YouTubeEmbed from './YoutubeEmbended.tsx';


const youtubeLinkRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;


function extractVideoId(url) {
    const match = url.match(youtubeLinkRegex);
    return match ? true : false;
}

const PostElement: React.FC<{ item: PostsProps }> = ({ item }) => {
    const navigate = useNavigate();
    const submit = useSubmit()

    useEffect(() => {
        const handleClick = (event) => {
            event.stopPropagation();
            const user_id = event.target.getAttribute('data-user');
            navigate('/user/' + user_id)
        };

        // Agrega event listener a todos los elementos con name="mention" cuando el componente se monta
        const mentionElements = document.getElementsByName('mention');
        mentionElements.forEach((element) => {
            element.addEventListener('click', handleClick);
        });

        // Elimina los event listeners al desmontar el componente para evitar memory leaks
        return () => {
            mentionElements.forEach((element) => {
                element.removeEventListener('click', handleClick);
            });
        };
    }, [])

    const goToUser = (event) => {
        event.stopPropagation();
        navigate('/user/' + item.postedBy)
    }

    const goToPost = (event) => {
        event.stopPropagation();
        navigate('/post/' + item.postID)
    }

    const deletePost = (event) => {
        event.stopPropagation();
        if(window.confirm("¿Quieres eliminar esta publicación?")){
            var form = new FormData()
            form.append('delete_post', 'true');
            form.append('postID', item.postID);
            submit(form, { encType: "multipart/form-data", method: 'post' })
        }
    }

    if (item.postImage) return (
        <div onClick={goToPost} className='post-container'>
            <div key={item.postID} style={{ background: 'white', width: '100%', flexDirection: 'row', display: 'flex', padding: '10px 5px 10px 15px', position: 'relative' }}>
                <img onClick={goToUser} src={BASE_URL + item.profileImage} style={{ width: '60px', height: '60px', borderRadius: '15px', objectFit: 'cover' }} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <p onClick={goToUser} style={{ fontWeight: '600', fontSize: '1.25em', lineHeight: '1em', margin: 0, flex: 1, marginBottom:0 }}>{item.screenName}</p>
                        <p style={{ color: '#00000055', margin: 0, fontSize: '.9em' }}>{timeDifference(item.postedOn)}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20px" height="20px"><path fill='#00000033' d="M6.22 3.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L9.94 8 6.22 4.28a.75.75 0 0 1 0-1.06Z"></path></svg>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: item.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }} />
                    {item.postImage && <img src={BASE_URL + item.postImage} style={{ height: '100px', borderRadius: '10px', marginTop: '10px', }} />}
                </div>
                {item.owner ? <div onClick={deletePost} style={{ position: 'absolute', bottom: '5px', right: '5px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px', paddingLeft: '2px' }}>
                    <TrashIcon fill='#00000044' />
                </div> : null}
            </div>
            {item.comments && <div style={{ background: '#F7F7F7', paddingLeft: '50px', paddingTop: '5px', paddingBottom: '8px' }}>
                {item.comments.map((item, index) =>
                    <div key={item.commentID} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                        <img src={BASE_URL + item.profileImage} style={{ width: '30px', height: '30px', borderRadius: '15px', alignSelf: 'flex-start', objectFit: 'cover', minWidth: '30px' }} />
                        <p style={{ margin: 0, marginLeft: '5px', fontSize: '1em', lineHeight: '1.1em', wordBreak: 'break-word', width: 'inherit' }}><span style={{ fontWeight: '600', margin: 0 }}>{item.screenName}</span> <span dangerouslySetInnerHTML={{ __html: item.comment }} /></p>
                    </div>
                )}
            </div>}
        </div>
    )

    if (extractVideoId(item.status)) return (
        <div onClick={goToPost} className='post-container'>
            <div key={item.postID} style={{ background: 'white', width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', position: 'relative' }}>
                <img onClick={goToUser} src={BASE_URL + item.profileImage} style={{ width: '60px', height: '60px', borderRadius: '15px', objectFit: 'cover' }} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                    <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.25em', lineHeight: '1em', margin: 0, marginBottom: '4px' }}>{item.screenName}</p>
                    <p dangerouslySetInnerHTML={{ __html: item.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }} />
                    <div style={{ aspectRatio: '1.77', borderRadius: '10px', marginTop: '15px', width: '90%' }}>
                        <YouTubeEmbed url={item.status} />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                    <div style={{ alignSelf: 'center' }}><ChevronRightIcon fill={'#00000088'} size={30} /></div>
                </div>
                <p style={{ position: 'absolute', top: '4px', right: '10px', color: '#00000055', margin: 0, fontSize: '.85em' }}>{timeDifference(item.postedOn)}</p>
                {item.owner ? <div onClick={deletePost} style={{ position: 'absolute', bottom: '5px', right: '5px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px', paddingLeft: '2px' }}>
                    <TrashIcon fill='#00000044' />
                </div> : null}
            </div>
            {item.comments && <div style={{ background: '#F7F7F7', paddingLeft: '50px', paddingTop: '5px', paddingBottom: '8px' }}>
                {item.comments.map((item, index) =>
                    <div key={item.commentID} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                        <img src={BASE_URL + item.profileImage} style={{ width: '30px', height: '30px', borderRadius: '15px', alignSelf: 'flex-start', objectFit: 'cover', minWidth: '30px' }} />
                        <p style={{ margin: 0, marginLeft: '5px', fontSize: '1em', lineHeight: '1.1em', wordBreak: 'break-word', width: 'inherit' }}><span style={{ fontWeight: '600', margin: 0 }}>{item.screenName}</span> <span dangerouslySetInnerHTML={{ __html: item.comment }} /></p>
                    </div>
                )}
            </div>}
        </div>
    )

    return (
        <div onClick={goToPost} className='post-container'>
            <div key={item.postID} style={{ background: 'white', width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', position: 'relative' }}>
                <img onClick={goToUser} src={BASE_URL + item.profileImage} style={{ width: '60px', height: '60px', borderRadius: '15px', objectFit: 'cover' }} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <p onClick={goToUser} style={{ fontWeight: '600', fontSize: '1.25em', lineHeight: '1em', margin: 0, flex: 1 }}>{item.screenName}</p>
                        <p style={{ color: '#00000055', margin: 0, fontSize: '.9em' }}>{timeDifference(item.postedOn)}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20px" height="20px"><path fill='#00000033' d="M6.22 3.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L9.94 8 6.22 4.28a.75.75 0 0 1 0-1.06Z"></path></svg>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: item.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }} />
                </div>
                {item.owner ? <div onClick={deletePost} style={{ position: 'absolute', bottom: '5px', right: '5px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px', paddingLeft: '2px' }}>
                    <TrashIcon fill='#00000044' />
                </div> : null}
            </div>
            {item.comments && <div style={{ background: '#F7F7F7', paddingLeft: '50px', paddingTop: '5px', paddingBottom: '8px' }}>
                {item.comments.map((item, index) =>
                    <div key={item.commentID} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                        <img src={BASE_URL + item.profileImage} style={{ width: '30px', height: '30px', borderRadius: '15px', alignSelf: 'flex-start', objectFit: 'cover', minWidth: '30px' }} />
                        <p style={{ margin: 0, marginLeft: '5px', fontSize: '1em', lineHeight: '1.1em', wordBreak: 'break-word', width: 'inherit' }}><span style={{ fontWeight: '600', margin: 0 }}>{item.screenName}</span> <span dangerouslySetInnerHTML={{ __html: item.comment }} /></p>
                    </div>
                )}
            </div>}
        </div>
    )
}

export default PostElement