import React, { useEffect, useState } from 'react'
import { Form, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { getEvent, submitEvent, updateEvent } from "../contacts";
import {
    FileMediaIcon
} from '@primer/octicons-react'
import StatusBar from '../StatusBar.tsx';
import { redirect } from "react-router-dom";
import { BASE_URL } from '../variables.ts';
import Compressor from 'compressorjs';

export async function loader({ request, params }) {
    if (params.eventID) {
        const event = await getEvent(params.eventID);
        if (event.error_code === 'event_not_found') {
            return redirect('/events')
        }
        return event
    }
    return null;
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if (params.eventID) {
        await updateEvent({
            eventID: params.eventID,
            event_name: formData.get("event_name"),
            event_description: formData.get("event_description"),
            event_place: formData.get("event_place"),
            event_date: formData.get("event_date"),
            event_private: formData.get("event_private") ? 1 : 0,
            file: formData.get("event_image")
        });
        return redirect('/event/' + params.eventID)
    }
    await submitEvent({
        event_name: formData.get("event_name"),
        event_description: formData.get("event_description"),
        event_place: formData.get("event_place"),
        event_date: formData.get("event_date"),
        event_private: formData.get("event_private") ? 1 : 0,
        file: formData.get("event_image")
    });
    return redirect('/events')
}

const EventCreator: React.FC = () => {
    const event = useLoaderData()
    const [image, setImage] = useState<File | Blob | null>()
    const submit = useSubmit()

    useEffect(() => {
        if (event) {
            if (event.eventImage) {
                setImage(event.eventImage)
            }
            (document.getElementById("event_name") as HTMLInputElement).value = event.name;
            (document.getElementById("event_description") as HTMLInputElement).value = event.description;
            (document.getElementById("event_place") as HTMLInputElement).value = event.place;
            (document.getElementById("event_date") as HTMLInputElement).value = event.date;
            (document.getElementById("event_private") as HTMLInputElement).checked = event.private;
        }
    }, [])



    const onImageSelect = (event) => {
        const file = event.target.files[0];
        // Crear una instancia del compresor
        new Compressor(file, {
            maxWidth: 720,
            quality: 0.85, // Ajusta la calidad de la imagen comprimida
            success(result) {
                // `result` es la imagen comprimida
                setImage(result);
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    const goBack = () => {
        window.history.back()
    }

    const getImageURL = () => {
        if (image) {
            if (typeof image == 'string') {
                return BASE_URL + image
            } else {
                return URL.createObjectURL(image)
            }
        }
        return undefined
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData(e.currentTarget);
        if (image && typeof image !== 'string') {
            if (formData.get('event_image')) {
                formData.set('event_image', image, image.name)
            } else {
                formData.append('event_image', image, image.name)
            }
        }
        submit(formData, {
            encType: "multipart/form-data",
            method: "post"
        })
    };

    const EventImage = () => {
        if (image) return (
            <label htmlFor="event_image" style={{ borderRadius: '50px', background: 'url(' + getImageURL() + ') center/cover', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px', position: 'relative' }}>
                 <div style={{position: "absolute", bottom: 0, right: 0, background: "linear-gradient(#0e8dd6, #0076BC, #066ba5)", borderRadius: "30px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '37px', width: '37px', boxShadow: '0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2)'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="22px" height="22px">
                        <path fill='white' d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path>
                    </svg>
                </div>
            </label>
        )
        return (
            <label htmlFor="event_image" style={{ borderRadius: '50px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px', position: 'relative' }}>
                <FileMediaIcon fill='white' size={35} />
                <div style={{position: "absolute", bottom: 0, right: 0, background: "linear-gradient(#0e8dd6, #0076BC, #066ba5)", borderRadius: "30px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '37px', width: '37px', boxShadow: '0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2)'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="22px" height="22px">
                        <path fill='white' d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path>
                    </svg>
                </div>
            </label>
        )
    }

    return (
        <form onSubmit={handleSubmit} method="post" id="post" encType="multipart/form-data" style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar text='Editar evento' back />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '100px' }}>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginInline: '30px', alignItems: 'center', marginTop: '20px' }}>
                        <EventImage />
                        <input id='event_image' type='file' accept="image/*" onChange={onImageSelect} name='event_image' style={{ marginRight: '15px', width: '50px', display: 'none' }} />
                        <input id='event_name' name='event_name' autoComplete="off" style={{ height: '50px', width: '100%' }} placeholder='Nombre' />
                        <textarea id='event_description' name='event_description' autoComplete="off" style={{ height: '200px', width: '100%', marginTop: '15px' }} placeholder='Descripción...' />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', width: '100%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="38px" height="38px"><path d="m12.596 11.596-3.535 3.536a1.5 1.5 0 0 1-2.122 0l-3.535-3.536a6.5 6.5 0 1 1 9.192-9.193 6.5 6.5 0 0 1 0 9.193Zm-1.06-8.132v-.001a5 5 0 1 0-7.072 7.072L8 14.07l3.536-3.534a5 5 0 0 0 0-7.072ZM8 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 9Z"></path></svg>
                            <input id='event_place' name='event_place' autoComplete="off" style={{ height: '50px', width: '100%', marginLeft: '10px' }} placeholder='Lugar' />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', width: '100%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="34px" height="34px"><path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Zm7-3.25v2.992l2.028.812a.75.75 0 0 1-.557 1.392l-2.5-1A.751.751 0 0 1 7 8.25v-3.5a.75.75 0 0 1 1.5 0Z"></path></svg>
                            <input id='event_date' name='event_date' aria-label="Date and time" type="datetime-local" style={{ height: '50px', marginLeft: '12px', width: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="32px" height="32px"><path d="M10.5 0a5.499 5.499 0 1 1-1.288 10.848l-.932.932a.749.749 0 0 1-.53.22H7v.75a.749.749 0 0 1-.22.53l-.5.5a.749.749 0 0 1-.53.22H5v.75a.749.749 0 0 1-.22.53l-.5.5a.749.749 0 0 1-.53.22h-2A1.75 1.75 0 0 1 0 14.25v-2c0-.199.079-.389.22-.53l4.932-4.932A5.5 5.5 0 0 1 10.5 0Zm-4 5.5c-.001.431.069.86.205 1.269a.75.75 0 0 1-.181.768L1.5 12.56v1.69c0 .138.112.25.25.25h1.69l.06-.06v-1.19a.75.75 0 0 1 .75-.75h1.19l.06-.06v-1.19a.75.75 0 0 1 .75-.75h1.19l1.023-1.025a.75.75 0 0 1 .768-.18A4 4 0 1 0 6.5 5.5ZM11 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path></svg>
                            <p style={{ margin: 0, marginRight: '10px', fontSize: '1.15em', flex:1, marginInline: '10px' }}>¿El evento es privado?</p>
                            <input id='event_private' name='event_private' type="checkbox" style={{ height: '30px', width: '30px', borderRadius: '15px' }} />
                        </div>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginBottom: '10px', marginTop: '10px' }}>
                        <button type="button" onClick={goBack} style={{ flex: 1, height: '45px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                            <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Cancelar</p>
                        </button>
                        <button type='submit' style={{ flex: 1, height: '45px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                            <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Publicar</p>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}


export default EventCreator