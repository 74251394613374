import React, { useEffect } from 'react'
import { useLoaderData, useNavigate } from "react-router-dom";
import { getNews, submitPost } from "../contacts";
import { ChevronRightIcon, PencilIcon, PersonIcon, MentionIcon, SignOutIcon, FileMediaIcon, ImageIcon } from '@primer/octicons-react'
import { NewsProps } from '../Props';
import logo from '../imgs/tuenti.png';
import NotificationBadge from '../NotificationBadge.tsx';
import PostInput from '../PostInput.tsx';
import { PostData } from '../PostData.ts';
import { BASE_URL } from '../variables.ts';
import FullScreenIcon from '../FullScreenIcon.tsx';
import { requestForToken } from '../firebaseNotification/firebase.js';

export async function loader({ params }) {
    const posts = await getNews();
    return { posts };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    return submitPost({ status: formData.get("status"), file: formData.get("image") });
}

const News: React.FC = () => {
    const { posts } = useLoaderData() as any || {};

    useEffect(()=>{
        requestForToken()
    },[])

    const logOut = async () => {
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        await PostData('logout.php')
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%', height: '55px', background: '#0076BC', alignItems: 'center', justifyContent: 'space-between', display: 'flex', paddingLeft: '15px', paddingRight: '10px' }}>
                <div style={{ height: '40px', width: '40px', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={logOut}>
                    <div style={{transform:'rotate(180deg)', marginBottom: '3px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="28px" height="28px"><path fill="white" d="M2 2.75C2 1.784 2.784 1 3.75 1h2.5a.75.75 0 0 1 0 1.5h-2.5a.25.25 0 0 0-.25.25v10.5c0 .138.112.25.25.25h2.5a.75.75 0 0 1 0 1.5h-2.5A1.75 1.75 0 0 1 2 13.25Zm10.44 4.5-1.97-1.97a.749.749 0 0 1 .326-1.275.749.749 0 0 1 .734.215l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734l1.97-1.97H6.75a.75.75 0 0 1 0-1.5Z"></path></svg>
                    </div>
                </div>
                <img src={logo} style={{ width: '120px', marginBottom: '1px' }} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <NotificationBadge />
                </div>
            </div>
            <PostInput padding />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', width: '100%' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%', display: 'flex' }}>
                    {posts && posts.map((item) => <NewComponent key={item.user_id} item={item} />)}
                </div>
            </div>
        </div>
    );
}

const NewComponent: React.FC<{ item: NewsProps }> = ({ item }) => {
    const navigate = useNavigate()

    const goToUser = (event) => {
        event.stopPropagation()
        navigate('/user/' + item.user_id)
    }

    const goToPost = (event) => {
        event.stopPropagation()
        navigate('/post/' + item.last_post_id)
    }

    const goToFriend = (event) => {
        event.stopPropagation()
        navigate('/user/' + item.last_friend_id)
    }

    const goToImagePost = (event) => {
        event.stopPropagation()
        const postID = event.target.getAttribute("data-postid")
        navigate('/post/' + postID)
    }

    const Label = ({ text = 'amigo', type = 'account', count = 0 }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', marginTop: '8px' }}>
                <div style={{ background: '#EFF9E9', flexDirection: 'row', display: 'flex', alignItems: 'center', borderRadius: '10px', paddingLeft: '5px' }}>
                    <Icon type={type} />
                    <div style={{ background: 'linear-gradient(#72B250, #509D2A)', borderRadius: '10px', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '4px' }}>
                        <p style={{ margin: 0, fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)', whiteSpace: 'nowrap' }}>{count} {text}{count > 1 ? 's' : ''}</p>
                    </div>
                </div>
                {type === 'amigo' && <p style={{margin:0, marginLeft: '5px', lineHeight: '1em', color: '#00000088', wordBreak: 'break-word'}}>{item.last_friend_name}</p>}
            </div>
        )
    }

    return (
        <div onClick={goToUser} key={item.user_id} className='new-container'>
            <img src={BASE_URL + item.profileImage} style={{ width: '75px', height: '75px', minWidth: '75px', borderRadius: '15px', objectFit: 'cover' }} />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start', flex:1  }}>
                <p style={{ fontWeight: 'bolder', fontSize: '1.25em', lineHeight: '1em', margin: 0, marginBottom: '4px' }}>{item.screenName}</p>
                {item.last_post && <p dangerouslySetInnerHTML={{ __html: item.last_post }} onClick={goToPost} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em' }} />}
                {item.num_posts > 0 && <Label text='estado' count={item.num_posts} type='estado' />}
                {item.num_friends > 0 && <Label text='amigo' count={item.num_friends} type='amigo' />}
                {item.num_images > 0 && <Label text='foto' count={item.num_images} type='foto' />}
                {item.images &&
                    <div style={{ marginTop: '10px' }}>
                        {item.images.map((image) => {
                            return <img key={image.postID} src={BASE_URL + image.postImage} data-postid={image.postID} onClick={goToImagePost} style={{ width: '60px', height: '60px', borderRadius: '10px', marginRight: '10px', objectFit: 'cover' }} />
                        })}
                    </div>
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                <div ><ChevronRightIcon fill={'#00000033'} size={30} /></div>
            </div>
        </div>
    )
}

const Icon = ({ type }) => {
    switch (type) {
        case 'estado': return <PencilIcon size={20} />
        case 'foto': return <ImageIcon size={20} />
        case 'amigo': return <PersonIcon size={20} />
        default: return <PencilIcon size={20} />
    }
}


export default News