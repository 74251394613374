import React, { useState } from 'react'
import './UserButtons.css'

type UserButtonsProps = {
    onPress: (op:number) => void;
    defaultValue?: number
}

const UserButtons: React.FC<UserButtonsProps> = ({onPress, value}) => {

    const onClick = (selection: number) =>{
        onPress && onPress(selection)
    }

    return (
        <div className='container-user-buttons'>
            <div className='user-buttons-background'>
                <div onClick={() => onClick(0)} className={value === 0 ? 'button left-button button-pressed' : 'button left-button' }>
                    <p>Tablón</p>
                </div>
                <div onClick={() => onClick(1)} className={value === 1 ? 'button button-pressed' : 'button'} >
                    <p>Fotos</p>
                </div>
                <div onClick={() => onClick(2)} className={value === 2 ? 'button right-button button-pressed' : 'button right-button'}>
                    <p>Amigos</p>
                </div>
            </div>
        </div>
    )
}

export default UserButtons;