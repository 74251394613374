import React, { useRef, useState } from 'react'
import { PostData } from '../PostData.ts'
import logo from '../imgs/tuenti.png';
import { redirect, useNavigate } from 'react-router-dom';

export async function loader() {
    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')
    if(email && password){
        const data = {login: true, email, password}
        PostData('getLogin.php', data).then((result: any) => {
            if (result && result.response) {
                if (result.data) {
                   return redirect('/home')
                }
            }
        })
    }
    const result = await PostData('getLogin.php') as any;
    if(result && result.data){
        return redirect('/home');
    }
    return null
}

export async function action() {
    return false
}

const Login: React.FC = () => {
    const [state, setState] = useState<'loading' | 'login' | 'register'>('login')
    const password = useRef<string>()
    const email = useRef<string>()
    const username = useRef<string>()
    const navigate = useNavigate()
    const code = useRef<string>()

    const logIn = async () => {
        email.current = (document.getElementById('email_input') as HTMLInputElement)?.value
        password.current = (document.getElementById('password_input') as HTMLInputElement)?.value
        username.current = (document.getElementById('username_input') as HTMLInputElement)?.value
        const data = {
            login: true,
            email: email.current,
            password: password.current
        }
        PostData('getLogin.php', data).then((result: any) => {
            if (result && result.response) {
                if (result.data) {
                    if(email.current) localStorage.setItem('email', email.current)
                    if(password.current) localStorage.setItem('password', password.current)
                    navigate('/home')
                }
            }
        })
    }

    const register = async () => {
        email.current = (document.getElementById('email_input') as HTMLInputElement)?.value
        password.current = (document.getElementById('password_input') as HTMLInputElement)?.value
        username.current = (document.getElementById('username_input') as HTMLInputElement)?.value
        code.current = (document.getElementById('code_input') as HTMLInputElement)?.value

        const data = {
            register: true,
            email: email.current,
            password: password.current,
            screenName: username.current,
            code: code.current
        }
        PostData('getLogin.php', data).then((result: any) => {
            if (result && result.response) {
                if (result.data) {
                    if(email.current) localStorage.setItem('email', email.current)
                    if(password.current) localStorage.setItem('password', password.current)
                    navigate('/home')
                }
            }
        })
    }

    const Content = () => {
        switch (state) {
            case 'login': return (
                <div className='login-container'>
                    <input id='email_input' type='text' style={{ width: '250px', marginBottom: '15px', height: '50px' }} placeholder='E-mail' ></input>
                    <input id='password_input' type='password' style={{ width: '250px', marginBottom: '20px', height: '50px' }} placeholder='Contraseña' ></input>
                    <button name='Login' className='login-button' onClick={logIn} >Entrar</button>
                    <p style={{ color: '#01729C', fontWeight: 'bold', fontSize: '.9em' }} onClick={() => setState('register')}>¿No tienes cuenta?</p>
                </div>
            )
            case 'register': return (
                <div className='login-container'>
                    <input id='email_input' type='text' style={{ width: '250px', marginBottom: '15px', height: '50px' }} placeholder='E-mail' ></input>
                    <input id='password_input' type='password' style={{ width: '250px', marginBottom: '15px', height: '50px' }} placeholder='Contraseña' ></input>
                    <input id='username_input' type='text' style={{ width: '250px', marginBottom: '15px', height: '50px' }} placeholder='Nombre' ></input>
                    <input id='code_input' type='text' style={{ width: '250px', marginBottom: '20px', height: '50px' }} placeholder='Código' ></input>
                    <button name='Register' className='login-button' onClick={register}>Regístrate</button>
                    <p style={{ color: '#01729C', fontWeight: 'bold', fontSize: '.9em' }} onClick={() => setState('login')}>¿Ya tienes una cuenta?</p>
                </div>
            )
            default: return null
        }
    }

    return (
        <div className="App" style={{ width: '100%', height: '100%', position: 'fixed' }} >
            <div className='background' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundPosition: 'center' }} >
                <img alt='logo' className='App-logo' style={{ width: '200px', marginBottom: '30px' }} src={logo} />
                <Content />
            </div>
        </div>
    )
}

export default Login