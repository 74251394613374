import { publish } from "./events.js";
import { BASE_URL } from "./variables.ts";

export function PostData(type: string, userData?: any) {
    let BaseURL = BASE_URL + 'core/ajax/';
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        if (!userData) {
            formData.append('data', '0')
        } else {
            for (var key in userData) {
                formData.append(key, userData[key]);
            }
        }
        fetch(BaseURL + type, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        })
            .then(async (response) => {
                response.json().then(result => {
                    if (result && result.error) {
                        if (result.error === 'error_not_logged') {
                            if (window.location.pathname !== '/') {
                                window.location.href = '/';
                            }
                        }
                    }
                    if(result && result.error_msg){
                        publish('show_toast', result.error_msg)
                    }
                    resolve(result)
                })
                    .catch((e) => {
                        console.log(e)
                        reject(e);
                    })

            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}
