import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { PostData } from '../PostData.ts';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBAr4LFGjnhWMfdzHiabj9rCACjbkGICns",
  authDomain: "tr3nt-7277a.firebaseapp.com",
  projectId: "tr3nt-7277a",
  storageBucket: "tr3nt-7277a.appspot.com",
  messagingSenderId: "949837077511",
  appId: "1:949837077511:web:27940e9da63c63a0615ea2"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BLyZCWekLJj9edz61z8Qs2UwEli7Umi3lpFwttx0LaiMXHFjfTlMquRvjAD9KTY42O71x-m6mZtOTOwOJ2LRNLo` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                PostData('setUserToken.php', {tokenID: currentToken})
                if(localStorage.getItem('fcmToken') && currentToken !==localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }

                else if(!localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }


            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});