import React from 'react'
import { useLoaderData, useNavigate } from "react-router-dom";
import { CalendarIcon, ChevronRightIcon, CommentIcon, MentionIcon, PencilIcon, PersonAddIcon, PersonIcon, PlusIcon } from '@primer/octicons-react'
import { getNotifications } from '../contacts';
import { NotificationProps } from '../Props';
import { timeDifference } from '../utils.ts';
import StatusBar from '../StatusBar.tsx';
import { BASE_URL } from '../variables.ts';

export async function loader({ params }) {
    const notifications = await getNotifications();
    return { notifications };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    return null

}

const Notifications: React.FC = () => {
    const { notifications } = useLoaderData() as { notifications: NotificationProps[] } || {};

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar back text='Notificaciones' />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                    {notifications.map((item, index) => <Notification key={item.notificationID} item={item} />)}
                </div>
            </div>
        </div>
    );
}

const Notification: React.FC<{ item: NotificationProps }> = ({ item }) => {
    const navigate = useNavigate()

    const getText = () => {
        switch (item.type) {
            case 'friend': return <span> y tú ahora sois amigos</span>
            case 'friend_request': return <span> quiere ser tu amigo</span>
            case 'event': return <span> te ha invitado a un evento</span>
            case 'post': return <span> ha escrito en tu tablón: <span style={{ color: 'grey' }}>{item.post}</span></span>
            case 'comment': return <span> ha escrito un comentario en tu estado</span>
            case 'comment_other': return <span> también ha escrito en un estado</span>
            case 'mention': {
                switch(item.mention_type){
                    case 'post': return <span> te ha mencionado en un post</span>
                    case 'comment': return <span> te ha mencionado en un comentario de un estado</span>
                    case 'event_post': return <span> te ha mencionado en un evento</span>
                    case 'event_comment': return <span> te ha mencionado en un comentario de un estado</span>
                    default: return <span> te ha mencionado en un comentario de un post</span>
                }
            }
        }
    }

    const getIcon = () => {
        switch (item.type) {
            case 'friend': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PersonIcon fill='white' size={20} />
            </div>
            case 'friend_request': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '30px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PersonAddIcon fill='white' size={18} />
            </div>
            case 'event': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CalendarIcon fill='white' size={20} />
            </div>
            case 'post': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PencilIcon fill='white' size={18} />
            </div>
            case 'comment': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CommentIcon fill='white' size={20} />
            </div>
             case 'comment_other': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CommentIcon fill='white' size={20} />
            </div>
            case 'mention': return <div style={{ background: 'linear-gradient(#0383AA, #01729C, #025F8B)', position: 'absolute', top: '25px', left: '25px', borderRadius: '20px', width: '30px', height: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MentionIcon fill='white' size={20} />
            </div>
        }
    }

    const gotTo = async () => {
      
        switch (item.type) {
            case 'friend': navigate('/user/' + item.user_id)
            case 'friend_request': return navigate('/user/' + item.user_id)
            case 'event': return navigate('/home')
            case 'post': return navigate('/post/' + item.postID)
            case 'comment': return navigate('/post/' + item.postID)
            case 'comment_other': return navigate('/post/' + item.postID)
            case 'mention':{
                switch(item.mention_type){
                    case 'comment': return navigate('/post/' + item.postID)
                    case 'post': return navigate('/post/' + item.postID)
                    case 'event_comment': return navigate('/event/' + item.postID)
                    case 'event_post': return navigate('/event/' + item.postID)
                }
            }
        }
    }

    return (
        <div onClick={gotTo} key={item.postID} className={!item.status ? 'notification notification-unread' : 'notification'}>
            <div style={{ position: 'relative' }}>
                <img src={BASE_URL + item.profileImage} style={{ width: '45px', height: '45px', borderRadius: '25px', objectFit: 'cover' }} />
                {getIcon()}
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '15px', marginRight: '5px', alignItems: 'flex-start' }}>
                <p style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }}><span style={{ fontWeight: 'bold' }}>{item.screenName}</span> {getText()}</p>
                <p style={{ color: '#00000055', margin: 0, fontSize: '.85em' }}>{timeDifference(item.time)}</p>
            </div>
            {item.postImage && <img src={BASE_URL + item.postImage} style={{ height: '45px', width: '45px', borderRadius: '7px', marginRight:'5px' }} />}
            <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'center' }}><ChevronRightIcon fill={'#00000088'} size={30} /></div>
            </div>
        </div>
    )
}


export default Notifications