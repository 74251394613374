import React, { useCallback, useEffect, useRef, useState } from 'react'
import { redirect, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { deletePost, getPostByUserId, getUserData, submitPost } from "../contacts";
import { ChevronRightIcon, LocationIcon, MentionIcon, PencilIcon, RepoIcon } from '@primer/octicons-react'
import { PostsProps, UserProps } from '../Props.ts';
import FriendButton from '../FriendButton.tsx';
import UserButtons from '../UserButtons.tsx';
import PhotoGrid from '../PhotoGrid.tsx';
import PostInput from '../PostInput.tsx';
import StatusBar from '../StatusBar.tsx';
import PostElement from '../PostElement.tsx';
import { BASE_URL } from '../variables.ts';
import { PostData } from '../PostData.ts';


export async function loader({ params }) {
    const user_id = params.userID ? params.userID : null
    const posts = await getPostByUserId(user_id);
    const user = await getUserData(user_id, true)
    if (user && user.error_code == 'user_not_found') {
        return redirect('/home')
    }
    return { posts, user };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if (formData.get('delete_post')) {
        return deletePost(formData.get('postID'))
    }
    let updates = {}
    updates['status'] = formData.get("status")
    updates['file'] = formData.get("image")
    if (params.userID) updates['user_id'] = params.userID
    return submitPost(updates);
}

const User: React.FC = () => {
    const { posts, user } = useLoaderData() as any || {};
    const [page, setPage] = useState<number>(0)
    const [showEdit, setShowEdit] = useState(false)
    const [showPicture, setShowPicture] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setPage(0)
    }, [user.user_id])


    const onClickButton = (op: number) => {
        setPage(op)
    }

    const deleteUser = async () => {
        if (window.confirm("¿Estás seguro que quieres eliminar tu cuenta?") == true) {
            await PostData('deleteUser.php')
        }

    }

    const RenderContent = () => {
        if (!posts || posts.error_code == "not_friend") {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '40px' }}>
                    <p style={{ margin: 0, fontSize: '1.5em', color: '#00000044', fontWeight: 'bold' }}>No sois amigos...</p>
                    <p style={{ margin: 0, fontSize: '1em', color: '#00000044', fontWeight: 'bold', marginTop: '14px' }}>De momento ;)</p>
                </div>
            )

        }
        switch (page) {
            case 0: return <PostsContainer posts={posts} />
            case 1: return <PhotoGrid user_id={user.user_id} />
            case 2: return <InfoContainer user={user} />
        }
    }

    const EditButtons = () => {
        if (user.owner && showEdit) return (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <button type='button' onClick={deleteUser} style={{ flex: 1, height: '40px', position: 'relative', background: 'linear-gradient(#e20f48, #bf093a)', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Eliminar</p>
                </button>
                <button type='button' onClick={() => navigate('/edit_profile')} style={{ flex: 1, height: '40px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Editar</p>
                </button>
            </div>
        )
        return null
    }

    const EditIcon = () => {
        if (user.owner) return (
            <button type='button' onClick={() => setShowEdit((state) => !state)} style={{ position: 'absolute', top: '15px', right: '15px', display: 'flex', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center' }}>
                <PencilIcon size={23} fill='white' />
            </button>
        )
        return null
    }

    const showProfilePicture = () => {
        setShowPicture(true)
    }

    const hideProfilePicture = () => {
        setShowPicture(false)
    }

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar back />
            <EditButtons />
            <div key={user.user_id} style={{ width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', position: 'relative' }}>
                <img onClick={showProfilePicture} src={BASE_URL + user.profileImage} style={{ width: '65px', height: '65px', borderRadius: '15px', objectFit: 'cover' }} />
                <EditIcon />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', alignItems: 'flex-start', marginRight: '5px' }}>
                    <p style={{ fontWeight: 'bolder', fontSize: '1.4em', lineHeight: '.9em', margin: 0, marginRight: '50px' }}>{user.screenName}</p>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '50px' }}>
                        <p style={{ margin: 0, fontSize: '1.2em', lineHeight: '1em', marginTop: '5px' }}>{user.friends_count} amigos</p>
                        <p style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em', marginTop: '5px', color: 'grey' }}>{user.visitas} visitas</p>
                    </div>
                    <FriendButton friend={user.user_id} />
                </div>
            </div>
            <UserButtons value={page} onPress={onClickButton} />
            <RenderContent />
            {showPicture ?
                <div onClick={hideProfilePicture} style={{ background: "#000000cc", position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <img src={BASE_URL + user.profileImage} style={{ width: '100%' }} />
                    <div onClick={hideProfilePicture}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="36px" height="36px" style={{ position: 'absolute', top: "12px", right: "12px" }}><path fill="white" d="M3.72 3.72a.75.75 0 0 1 1.06 0L8 6.94l3.22-3.22a.749.749 0 0 1 1.275.326.749.749 0 0 1-.215.734L9.06 8l3.22 3.22a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L8 9.06l-3.22 3.22a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L6.94 8 3.72 4.78a.75.75 0 0 1 0-1.06Z"></path></svg>
                    </div>
                </div> : null}
        </div>
    );
}

const InfoContainer: React.FC<{ user: UserProps }> = ({ user }) => {
    const navigate = useNavigate()

    return (
        <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
            <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                {user.friends.map((item, index) => (
                    <div onClick={() => navigate('/user/' + item.user_id)} key={item.user_id} className='message-container'>
                        <img src={BASE_URL + item.profileImage} style={{ width: '45px', height: '45px', borderRadius: '30px', objectFit: 'cover' }} />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', justifyContent: 'center' }}>
                            <p style={{ fontSize: '1.2em', lineHeight: '1em', margin: 0, fontWeight: '500' }}>{item.screenName}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                            <div style={{ alignSelf: 'center' }}><ChevronRightIcon fill={'#00000033'} size={30} /></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const PostsContainer: React.FC<{ posts: PostsProps[] | { error_code: string } }> = ({ posts }) => {
    return (
        <>
            <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                    <PostInput />
                    {posts && posts.map((item, index) => <PostElement key={item.postID} item={item} />)}
                </div>
            </div>
        </>
    )
}

export const defaultMentionStyle = {
    control: {
        backgroundColor: '#fff',
        fontSize: '1em',
        fontWeight: 'normal',
        minHeight: '50px',
        borderRadius: '10px',
        padding: '0.5rem 0.75rem',
        margin: 0,
        lineHeight: '1.1em',
        background: 'transparent',
        flex: 1,
    },

    '&multiLine': {
        flex: 1,
        minHeight: '50px',
        borderRadius: '10px',
        lineHeight: '1.1em',
        highlighter: {
            visibility: 'visible',
            border: '2px inset transparent',
            lineHeight: '1.1em',
        },
        input: {
            minHeight: '50px',
            lineHeight: '1.1em',
            borderRadius: '10px',
            background: 'white'
        },
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            borderRadius: '10px',
            overflow: 'hidden',
            fontSize: '1em',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
        },
        item: {
            padding: '5px 15px',
            fontSize: '1em',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}



export default User