import React from 'react'
import { Form, useLoaderData, useNavigate, useNavigation, useSubmit } from "react-router-dom";
import { getRecentMessages, searchFriends, submitComment } from "../contacts";
import { CheckIcon, ChevronRightIcon } from '@primer/octicons-react'
import StatusBar from '../StatusBar.tsx';
import { BASE_URL } from '../variables.ts';

export async function loader({ request, params }) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q");
    let messages = []
    if (!q || q == '') {
        messages = await getRecentMessages() as [];
    } else {
        messages = await searchFriends(q) as [];
    }
    return { q, messages };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    return submitComment(params.postID, formData.get("status"), formData.get('friendID'));
}

const Messages: React.FC = () => {
    const { q, messages } = useLoaderData() as any || {};
    const navigation = useNavigation();
    const submit = useSubmit();

    const searching =
        navigation.location &&
        new URLSearchParams(navigation.location.search).has(
            "q"
        );

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar text='Chats' back />
            <Form id="search-form" role="search" style={{ padding: '15px', display: 'flex', width: '100%', position: 'relative' }}>
                <input
                    id="q"
                    aria-label="Search contacts"
                    placeholder="Escribe aquí..."
                    autoComplete="off"
                    type="search"
                    name="q"
                    defaultValue={q}
                    style={{ 
                        height: '45px', 
                        flex: 1,
                    }}
                    className={searching ? "loading" : ""}
                    onChange={(event) => {
                        const isFirstSearch = q == null;
                        submit(event.currentTarget.form, {
                            replace: !isFirstSearch,
                        });
                    }}
                />
                <div id="search-spinner" aria-hidden hidden={!searching} />
                <div className="sr-only" aria-live="polite"></div>

            </Form>

            <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                    {messages.map((item, index) => <ChatComponent key={item.user_id} item={item} />)}
                </div>
            </div>
        </div>
    );
}

const ChatComponent = ({item}) => {
    const navigate = useNavigate()

    const getText = () =>{
        if(!item.message) return <i>Pulsa para iniciar un chat</i>
        if(item.message === ':vibrate') return <span><i>Se ha enviado un zumbido</i> 🫨</span>
        return item.message
    }

    const text = getText()

    return (
        <div key={item.user_id} onClick={() => navigate('/chat/' + item.user_id)} className='message-container'>
            <img src={BASE_URL+item.profileImage} style={{ width: '50px', height: '50px', borderRadius: '30px', objectFit:'cover', marginBlock: '8px' }} />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                <p style={{ fontWeight: 'bolder', fontSize: '1.15em', lineHeight: '1em', margin: 0, }}>{item.screenName}</p>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <p style={{ margin: 0, marginBlock: '3px', fontSize: '1.1em', lineHeight: '1.2em', wordBreak:'break-word', color: 'gray' }}>{text}</p>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                <div style={{}}><ChevronRightIcon fill={'#00000044'} size={30} /></div>
            </div>
        </div>
    )
}

export default Messages