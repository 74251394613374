import React, { useEffect, useState } from "react";
import { Form, useLoaderData, redirect, useNavigate, useSubmit } from "react-router-dom";
import { getUserData, updateContact, updateProfile } from "../contacts";
import StatusBar from "../StatusBar.tsx";
import { FileMediaIcon } from "@primer/octicons-react";
import { BASE_URL } from "../variables.ts";
import { UserProps } from "../Props.ts";
import Compressor from 'compressorjs';

export async function loader({ request, params }) {
    const user = await getUserData(null)
    return user
}

export async function action({ request, params }) {
    let formData = await request.formData();
    await updateProfile({
        screenName: formData.get("screenName"),
        bio: formData.get("bio"),
        city: formData.get("city"),
        web: formData.get("web"),
        birthday: formData.get("birthday"),
        file: formData.get("image")
    });
    return redirect('/user')
}

const EditContact: React.FC = () => {
    const user = useLoaderData() as UserProps
    const [image, setImage] = useState<File | Blob | string | null>()
    const submit = useSubmit()

    useEffect(() => {
        if (user && user.profileImage) {
            setImage(user.profileImage)
        }
    }, [])

    const onImageSelect = (event) => {
        const file = event.target.files[0];
        // Crear una instancia del compresor
        new Compressor(file, {
            maxWidth: 720,
            quality: 0.85, // Ajusta la calidad de la imagen comprimida
            success(result) {
                // `result` es la imagen comprimida
                setImage(result);
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    const goBack = () => {
        window.history.back()
    }

    const getImageURL = () => {
        if (image) {
            if (typeof image == 'string') {
                return BASE_URL + image
            } else {
                return URL.createObjectURL(image)
            }
        }
        return undefined
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData(e.currentTarget);
        if (image && typeof image !== 'string') {
            if (formData.get('image')) {
                formData.set('image', image, image.name)
            } else {
                formData.append('image', image, image.name)
            }
        }
        submit(formData, {
            encType: "multipart/form-data",
            method: "post"
        })
    };

    const ProfileImage = () => {
        if (image) return (
            <label htmlFor="image" style={{ borderRadius: '50px', background: 'url(' + getImageURL() + ') center/cover', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center',  marginBottom: '15px', position: 'relative' }}>
                 <div style={{position: "absolute", bottom: 0, right: 0, background: "linear-gradient(#0e8dd6, #0076BC, #066ba5)", borderRadius: "30px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '37px', width: '37px', boxShadow: '0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2)'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="22px" height="22px">
                        <path fill='white' d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path>
                    </svg>
                </div>
            </label>
        )
        return (
            <label htmlFor="image" style={{ borderRadius: '50px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px', position: 'relative' }}>
                <FileMediaIcon fill='white' size={35} />
                <div style={{position: "absolute", bottom: 0, right: 0, background: "linear-gradient(#0e8dd6, #0076BC, #066ba5)", borderRadius: "30px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '37px', width: '37px', boxShadow: '0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2)'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="22px" height="22px">
                        <path fill='white' d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path>
                    </svg>
                </div>
            </label>
        )
    }

    return (
        <form onSubmit={handleSubmit} method="post" id="post" encType="multipart/form-data" style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%', }}>
            <StatusBar text="Editar perfil" back />


            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginInline: '30px', alignItems: 'center', marginTop: '20px' }}>

                <input id='image' type='file' accept="image/*" onChange={onImageSelect} name='image' style={{ marginRight: '15px', width: '50px', display: 'none' }} />
                <ProfileImage />
                <input type='text' defaultValue={user.screenName} id='screenName' name='screenName' autoComplete="off" style={{ height: '50px', width: '100%' }} placeholder='Nombre' />
                <textarea defaultValue={user.bio as string} id='bio' name='bio' autoComplete="off" style={{ height: '100px', width: '100%', marginTop: '15px' }} placeholder='Biografía...' />
                <input type='text' defaultValue={user.city as string} id='city' name='city' autoComplete="off" style={{ height: '50px', width: '100%', marginTop: '15px' }} placeholder='Ciudad...' />
                <input type='text' defaultValue={user.website as string} id='web' name='web' autoComplete="off" style={{ height: '50px', width: '100%', marginTop: '15px' }} placeholder='Web...' />
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '15px' }}>
                    <img style={{ width: '50px', height: '50px', marginRight: '10px' }} src={require('../imgs/cake.png')} />
                    <input defaultValue={user.birthday} id='birthday' name='birthday' aria-label="Date" type="date" style={{ height: '50px' }} />
                </div>
            </div>
            <div style={{ flexDirection: 'row', display: 'flex', marginBottom: '10px' }}>
                <button type="button" onClick={goBack} style={{ flex: 1, height: '45px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Volver</p>
                </button>
                <button type='submit' style={{ flex: 1, height: '45px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Guardar</p>
                </button>
            </div>
        </form>
    );
}

export default EditContact