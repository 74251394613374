import React from 'react'
import { Form, useLoaderData, useNavigate, useSubmit, useNavigation } from "react-router-dom";
import { ChevronLeftIcon, PlusIcon } from '@primer/octicons-react'
import { getEvents, searchEvent } from '../contacts';
import { BASE_URL } from '../variables.ts';


export async function loader({ request, params }) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q");
    var events = []
    if (!q || q == '') {
        events = await getEvents() as [];
    } else {
        events = await searchEvent(q) as [];
    }
    return { q, events };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    return null

}

const Events: React.FC = () => {
    const { q, events } = useLoaderData() as any || {};
    const navigate = useNavigate()
    const navigation = useNavigation();
    const submit = useSubmit();

    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };


    const renderEventos = () => {
        // Creamos un objeto donde cada clave es una fecha y su valor es un array de eventos para esa fecha
        const eventosPorDia = events.reduce((acc, evento) => {
            const fecha = formatDate(evento.date);
            if (!acc[fecha]) {
                acc[fecha] = [];
            }
            acc[fecha].push(evento);
            return acc;
        }, {});

        // Renderizamos los eventos agrupados por día
        return Object.entries(eventosPorDia).map(([fecha, eventos]) => (
            <div key={fecha}>
                <p style={{ margin: 0, background: '#F7F7F7', padding: '6px 15px', fontWeight: '600', fontSize: '0.9em' }}>{fecha}</p>
                <div>
                    {eventos.map((evento) => <Evento key={evento.id} item={evento} />)}
                </div>
            </div>
        ));
    };

    const searching =
        navigation.location &&
        new URLSearchParams(navigation.location.search).has(
            "q"
        );

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%', height: '55px', background: '#0076BC', alignItems: 'center', justifyContent: 'space-between', display: 'flex', paddingLeft: '15px', paddingRight: '15px' }}>
                <div style={{ height: '40px', width: '40px', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { window.history.back() }}>
                    <ChevronLeftIcon fill={'white'} size={35} />
                </div>
                <p style={{ color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)', fontSize: '1.6em', fontWeight: 'bold', maxWidth: '75%', whiteSpace: 'nowrap', overflow: 'hidden', overflowWrap: 'break-word', textOverflow: 'ellipsis' }}>Eventos</p>
                <div onClick={() => { navigate('/event_creator') }} style={{ height: '40px', width: '40px', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PlusIcon fill={'white'} size={40} />
                </div>
            </div>
            <Form id="search-form" role="search" style={{ padding: '15px', display: 'flex', width: '100%', position: 'relative' }}>
                <input
                    id="q"
                    aria-label="Search contacts"
                    placeholder="Buscar evento..."
                    autoComplete="off"
                    type="search"
                    name="q"
                    defaultValue={q}
                    style={{ height: '45px', flex: 1 }}
                    className={searching ? "loading" : ""}
                    onChange={(event) => {
                        const isFirstSearch = q == null;
                        submit(event.currentTarget.form, {
                            replace: !isFirstSearch,
                        });
                    }}
                />
                <div id="search-spinner" aria-hidden hidden={!searching} />
                <div className="sr-only" aria-live="polite"></div>

            </Form>
            <div style={{ height: '100%', overflow: 'auto' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%', paddingBottom: '20px', marginBottom: '20px' }}>
                    {renderEventos()}
                </div>
            </div>
        </div>
    );
}

const Evento: React.FC<{ item: { name: string, eventID: number, type: string, date: Date, eventImage: string, description: string } }> = ({ item }) => {
    const navigate = useNavigate()

    const Image = () => {
        if (item.type === 'birthday') return (
            <div style={{ width: '60px', height: '60px', display: 'flex', position: 'relative' }}>
                <img src={BASE_URL + item.eventImage} style={{ width: '60px', height: '60px', borderRadius: '10px', objectFit: 'cover' }} />
                <div style={{ position: 'absolute', background: 'linear-gradient(#0383AA, #01729C, #025F8B)', borderRadius: '30px', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', bottom: '-5px', right: '-5px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 2px' }}>
                    <img src={require('../imgs/cake.png')} style={{ width: '25px', height: '25px' }} />
                </div>
            </div>
        )
        if (item.eventImage) return (
            <img src={BASE_URL + item.eventImage} style={{ width: '60px', height: '60px', borderRadius: '10px', objectFit: 'cover' }} />
        )
        return null
    }

    const onClick = () => {
        if (item.type == 'birthday') return
        navigate('/event/' + item.eventID)
    }

    const Info = () => {
        if (item.type !== 'birthday') {
            return (
                <div style={{flexDirection:'row', display: 'flex', alignItems: 'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill='#00000066' d="m12.596 11.596-3.535 3.536a1.5 1.5 0 0 1-2.122 0l-3.535-3.536a6.5 6.5 0 1 1 9.192-9.193 6.5 6.5 0 0 1 0 9.193Zm-1.06-8.132v-.001a5 5 0 1 0-7.072 7.072L8 14.07l3.536-3.534a5 5 0 0 0 0-7.072ZM8 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 9Z"></path></svg>
                    <p style={{ margin: 0, color: '#00000066', fontWeight: 'bold', marginInline: '3px' }}> {item.place} </p>
                </div>


            )
        }
        return null
    }


    const getColor = () =>{
        if(item.type == "birthday") return '#dd2eb7';
        switch(item.status){
            case "yes": return '#39dd41'
            case "maybe": return '#ffa860'
            case "no": return '#d33939' 
            default: return '#c4c4c4'
        }
    }


    return (
        <div key={item.eventID} className='event-container' onClick={onClick}>
            <div style={{background: getColor(), borderRadius: '10px', width: '5px', display: 'flex', marginRight: '10px', marginBlock: '3px'}}>
            </div>
            <div style={{ flex: 1, marginRight: '8px' }}>
                <p style={{ margin: 0, fontWeight: 'bold', lineHeight: '1.15em', fontSize: '1.2em', marginBottom: '4px' }}>{item.name}</p>
                <p style={{ margin: 0, lineHeight: '1.2em', marginBottom: '4px' }}>{item.type == 'birthday' ? '¡Este día es su cumpleaños! 🎉' : item.description}</p>
                <Info />
            </div>
            <Image />
        </div>
    )
}

const getHours = (date: string) => {

    var fecha = new Date(date);

    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();

    hora = hora < 10 ? "0" + hora : hora;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    return hora + ":" + minutos
}

export default Events