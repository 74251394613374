export function timeDifference(date: string) {

    const postDate = new Date(date); // Convertir la fecha del post a un objeto Date
    const now = new Date(); // Fecha y hora actuales del cliente en la zona horaria local

    const elapsed = now - postDate; // Diferencia en milisegundos entre ahora y la fecha del post

    const seconds = Math.round(elapsed / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const months = Math.round(days / 30);
    const years = Math.round(days / 365);

    if (elapsed < 1000) {
        return 'ahora';
    } else if (seconds < 60) {
        return seconds + 'segs';
    } else if (minutes < 60) {
        return minutes + 'mins';
    } else if (hours < 24) {
        return hours + 'h';
    } else if (days < 30) {
        return days + 'd';
    } else if (months < 12) {
        return months + 'meses';
    } else {
        return years + 'años';
    }
}