import { ChevronLeftIcon } from '@primer/octicons-react'
import React from 'react'
import NotificationBadge from './NotificationBadge.tsx'
import logo from './imgs/tuenti.png';
import FullScreenIcon from './FullScreenIcon.tsx';


const StatusBar: React.FC<{ text?: string, back?: boolean }> = ({ text, back }) => {
    return (
        <div style={{ width: '100%', height: '55px', background: '#0076BC', alignItems: 'center', justifyContent: 'space-between', display: 'flex', paddingLeft: '15px', paddingRight: '10px' }}>
            {back && <div style={{ height: '40px', width: '40px', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => { window.history.back() }}>
                <ChevronLeftIcon fill={'white'} size={35} />
            </div>}
            {text ? <p style={{ color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)', fontSize: '1.6em', fontWeight: 'bold' }}>{text}</p> : <img src={logo} style={{ width: '120px', marginBottom: '1px' }} />}
            <NotificationBadge />
        </div>
    )
}

export default StatusBar