import React from 'react';

const youtubeLinkRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;


function extractVideoId(url) {
    const match = url.match(youtubeLinkRegex);
    return match ? match[1] : null;
  }

const YouTubeEmbed = ({ url, fullscreen = false }) => {
  const videoId = extractVideoId(url);

  if (!videoId) {
    return null; // No es un enlace de YouTube válido
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  const className = fullscreen ? "video-responsive video-responsive-no-borders" : "video-responsive"

  return (
    <div className={className}>
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeEmbed;

