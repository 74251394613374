
import './App.css';
import React, { useEffect, useState } from 'react';
import Navigator from './Navigator.tsx';
import Notification from './firebaseNotification/Notification.js';


function App() {

  return (
    <>
      <Notification />
      <Navigator />
    </>
  )
}
export default App;
