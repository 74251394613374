import React from 'react'
import { useLoaderData, useNavigate } from "react-router-dom";
import { deleteEventComment, getEventPost, submitEventComment } from "../contacts";
import { timeDifference } from '../utils.ts';
import StatusBar from '../StatusBar.tsx';
import PostInput from '../PostInput.tsx';
import CommentElement from '../CommentElement.tsx';
import { BASE_URL } from '../variables.ts';

export async function loader({ params }) {
    const { post, event, comments } = await getEventPost(params.eventPostID);
    return { post, event, comments };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if(formData.get('delete_comment')){
        return deleteEventComment(formData.get('commentID'))
    }
    return submitEventComment(params.eventPostID, formData.get("status"), formData.get('friendID'));
}

const EventPost: React.FC = () => {
    const { post, event, comments } = useLoaderData() as any || {};
    const navigate = useNavigate()

    const goToEvent = () =>{
        navigate('/event/' + event.eventID)
    }

    const goToUser = () => {
        navigate('/user/' + post.postedBy)
    }

    const Info = () => {
        if(post.user_id == post.friendID) return null
        return (
            <div onClick={goToEvent} style={{ padding: '5px 18px', display: 'flex', alignItems: 'center', minHeight: '45px' }}>
                <img src={BASE_URL + post.profileImage} style={{ width: '30px', height: '30px', borderRadius: '50px', objectFit: 'cover' }} />
                <img src={BASE_URL + event.eventImage} style={{ width: '30px', height: '30px', borderRadius: '50px', marginLeft: '-15px', objectFit: 'cover' }} />
                <p style={{ margin: 0, fontSize: '0.9em', lineHeight: '0.9em', marginLeft: '10px', color: '#00000099', paddingBottom: '8px', paddingTop: '8px' }} >{post.screenName} ha comentado en el evento {event.name}:</p>
            </div>
        )
    }

    if (post.postImage) {
        return (
            <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <StatusBar back />
                <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '100px' }}>
                        <Info />
                        <div key={post.postID} style={{ borderBottomWidth: '1px', background: '#F0EFF3', width: '100%', flexDirection: 'column', display: 'flex', padding: '15px', paddingBottom: 0, paddingTop: '5px', alignItems: 'center' }}>
                            <img src={BASE_URL + post.postImage} style={{ maxWidth: '95%', maxHeight: '55vh', borderRadius: '10px', marginTop: '10px', marginBottom: '10px', objectFit: 'contain', boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px' }} />
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                                <img onClick={goToUser} src={BASE_URL + post.profileImage} style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit:'cover' }} />
                                <div style={{ marginLeft: '10px' }}>
                                    <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.25em', lineHeight: '1em', margin: 0, marginBottom: '4px' }}>{post.screenName}</p>
                                    <p dangerouslySetInnerHTML={{ __html: post.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }} />
                                </div>
                            </div>
                        </div>
                        <PostInput user_id={post.postedBy} comment padding />
                        <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                            {comments.map((item, index) => <CommentElement item={item} />)}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar back />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '100px' }}>
                    <Info />
                    <div key={post.postID} style={{ borderBottomWidth: '1px', background: '#F0EFF3', width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', paddingBottom: 0 }}>
                        <img onClick={goToUser} src={BASE_URL + post.profileImage} style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit:'cover' }} />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                            <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.25em', lineHeight: '1em', margin: 0, marginBottom: '4px'}}>{post.screenName}</p>
                            <p dangerouslySetInnerHTML={{ __html: post.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em' }} />
                        </div>
                    </div>
                    <PostInput user_id={post.postedBy} comment padding />
                    <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                        {comments.map((item, index) => <CommentElement item={item} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EventPost