import React, { useEffect, useRef, useState } from "react";
import { PostData } from "./PostData.ts";

const FriendButton: React.FC<{ friend: number }> = ({ friend }) => {
    const [status, setFriendStatus] = useState<string | null | undefined>()
    const loading = useRef(false)

    useEffect(() => {
        getFriendStatus()
    }, [])


    const acceptFriendRequest = async() =>{
        if(loading.current) return
        loading.current = true
        setFriendStatus('friend')
        await PostData('addFriend.php', { friend: friend })
        loading.current = false
    }

    const sendFriendRequest = async() =>{
        if(loading.current) return
        loading.current = true
        setFriendStatus('waiting')
        await PostData('sendFriendRequest.php', { friend: friend })
        loading.current = false
    }

    const getFriendStatus = async () => {
        const result = await PostData('getFriendStatus.php', { friendID: friend }) as { status: string }
        setFriendStatus(result?.status)
    }

    if (status === 'none') return (
        <div onClick={sendFriendRequest} style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '5px' }}>
            <div style={{ background: 'linear-gradient(#72B250, #509D2A)', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 2px' }}>
                <p style={{ margin: 0, fontWeight: 'bold', color: 'white', fontSize: '1.1em', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Agregar</p>
            </div>
        </div>
    )

    if (status === 'waiting') return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '5px' }}>
            <div style={{ background: 'linear-gradient(#d60876, #db2385)', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', marginLeft: '4px', boxShadow: 'inset rgba(0, 0, 0, 0.2) 0px 2px 4px' }}>
                <p style={{ margin: 0, fontWeight: 'bold', color: 'white', fontSize: '1.1em', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Solicitado</p>
            </div>
        </div>

    )

    if (status === 'asking') return (
        <div onClick={acceptFriendRequest} style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '5px' }}>
            <div style={{ background: 'linear-gradient(#edb544, #c9901e)', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 2px' }}>
                <p style={{ margin: 0, fontWeight: 'bold', color: 'white', fontSize: '1.1em', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Aceptar petición</p>
            </div>
        </div>
    )

    if (status === 'friend') return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '5px' }}>
            <div style={{ background: 'linear-gradient(#3582e0, #2368bc)', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', marginLeft: '4px', boxShadow: 'inset rgba(0, 0, 0, 0.2) 0px 2px 4px' }}>
                <p style={{ margin: 0, fontWeight: 'bold', color: 'white', fontSize: '1.1em', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Amigos</p>
            </div>
        </div>
    )

    return null

   
}

export default FriendButton