import React, { useEffect, useState } from 'react'
import '../Navigator.css';
import './root.css';
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { LuHome, LuMessageSquare, LuCalendar, LuUser, LuSearch, LuArrowDownSquare } from "react-icons/lu";
import { PostData } from '../PostData.ts';
import { subscribe, unsubscribe } from "../events";
import { usePWAInstall } from 'react-use-pwa-install'
import { useSwipeable } from 'react-swipeable';
import { BASE_URL } from '../variables.ts';
import { XIcon } from '@primer/octicons-react';
import { motion, AnimatePresence } from 'framer-motion';

const Root: React.FC = () => {
    const { state, location } = useNavigation();
    const [path, setPath] = useState('/')

    useEffect(() => {
        if (location?.pathname && location.pathname != path) setPath(location.pathname)
    }, [location])



    return (
        <div className='root'>
            <div
                id="detail"
                className={
                    state === "loading" ? "loading" : ""
                }
            >
                <Outlet />
            </div>
            <Navbar />
            <ToastErrorMessage />
            <ToastNotificationMessage />
        </div>
    )
}

const Navbar = () => {
    const [hide, setHide] = useState(true)
    let path = useLocation();
    const navigate = useNavigate()
    const [isStandalone, setIsStandalone] = useState(false);
    const install = usePWAInstall()

    useEffect(() => {
        // Verificar si la aplicación está instalada en la pantalla de inicio en iOS
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (iOS && window.navigator.standalone) {
            setIsStandalone(true);
        } else {
            setIsStandalone(false);
        }
    }, []);

    const getPosition = () => {
        if (!path.pathname) return 0
        if (path.pathname === '/') return 0
        if (path.pathname.includes('/user')) return 1
        if (path.pathname === '/messages') return 2
        if (path.pathname.includes('/chat')) return 2
        if (path.pathname.includes('/event')) return 3
        if (path.pathname === '/search') return 4
        return 0
    }

    const num = getPosition()

    useEffect(() => {
        if (path.pathname == '/') {
            if (!hide) setHide(true)
        } else {
            if (hide) setHide(false)
        }
    }, [path, hide])

    if (hide) return null

    return (
        <div className={`navbar ${isStandalone ? 'navbar-ios' : ''}`}>
            <li onClick={() => navigate('/')} key={'home'}>
                <div className={num === 0 ? 'route-selected' : undefined}>
                    <LuHome color={num === 0 ? 'white' : '#919191'} strokeWidth={1.7} size={'30px'} />
                </div>
            </li>
            <li onClick={() => navigate('/user')} key={'profile'}>
                <div className={num === 1 ? 'route-selected' : undefined}>
                    <LuUser color={num === 1 ? 'white' : '#919191'} strokeWidth={1.7} size={'30px'} />
                </div>
            </li>
            <li onClick={() => navigate('/messages')} key={'chat'}>
                <div style={{ position: 'relative' }} className={num === 2 ? 'route-selected' : undefined}>
                    <LuMessageSquare color={num === 2 ? 'white' : '#919191'} strokeWidth={1.7} size={'30px'} />
                    <MessagesBadge />
                </div>
            </li>
            <li onClick={() => navigate('/events')} key={'events'}>
                <div className={num === 3 ? 'route-selected' : undefined}>
                    <LuCalendar color={num === 3 ? 'white' : '#919191'} strokeWidth={1.7} size={'30px'} />
                </div>
            </li>
            <li onClick={() => navigate('/search')} key={'search'}>
                <div className={num === 4 ? 'route-selected' : undefined}>
                    <LuSearch color={num === 4 ? 'white' : '#919191'} strokeWidth={1.7} size={'30px'} />
                </div>
            </li>
            {install && <li onClick={install} key={'install'}>
                <LuArrowDownSquare color={'white'} strokeWidth={1.7} size={'30px'} />
            </li>}

        </div>
    )
}

const ToastNotificationMessage = () => {
    const [toast, setToast] = useState<{ title: string, body: string, data: any } | null>()
    const handlers = useSwipeable({ onSwipedUp: () => setToast(null), trackMouse: true })
    const location = useLocation();
    const navigate = useNavigate()

    const showToast = (event) => {
        if (!event) return
        if (event.detail) {
            const data = event.detail.data
            if (data.chat) {
                const regex = new RegExp(`${'chat/' + data.senderID}(\\D|$)`);
                if (regex.test(location.pathname)) {
                    return
                }
            }
            setToast(event.detail)
            setTimeout(() => {
                //setToast(null)
            }, 3500)
        }
    }

    useEffect(() => {
        subscribe('notification', showToast)
        return () => {
            unsubscribe('notification', showToast)
        }
    }, [showToast])

    const goTo = () => {
        if (!toast) return
        if (toast.data.chat && toast.data.senderID) {
            navigate('/chat/' + toast.data.senderID)
        } else if (toast.data.type) {
            PostData('setNotificationViewed.php', { notificationID: toast.data.notificationID })
            switch (toast.data.type) {
                case 'friend': navigate('/user/' + toast.data.postID)
                    break
                case 'friend_request': navigate('/user/' + toast.data.postID)
                    break
                case 'event': navigate('/home')
                case 'post': navigate('/post/' + toast.data.postID)
                    break
                case 'comment': navigate('/post/' + toast.data.postID)
                    break
                case 'comment_other': navigate('/post/' + toast.data.postID)
                    break
                case 'mention': {
                    switch (toast.data.mention_type) {
                        case 'comment': navigate('/post/' + toast.data.postID)
                            break
                        case 'post': navigate('/post/' + toast.data.postID)
                            break
                        case 'event_comment': navigate('/event/' + toast.data.postID)
                            break
                        case 'event_post': navigate('/event/' + toast.data.postID)
                            break
                    }
                }
                    break
            }
        }
        setToast(null)
    }

    const closeToast = (event) => {
        event.stopPropagation()
        setToast(null)
    }

    return (
        <AnimatePresence>
            {toast && (
            <motion.div
                initial={{ y: 0 }}
                exit={{ y: -150 }} // El elemento se desvanece y se mueve hacia arriba
                transition={{ duration: 0.4 }} // Con
                style={{ position: 'absolute', top: '20px', left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'opacity 1s' }}>
                <div {...handlers} onClick={goTo} style={{ background: 'linear-gradient(#fcfcfc, #eaeaea)', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 2px', width: '90%', display: 'flex', flexDirection: 'row', padding: '12px 12px', position: 'relative' }}>
                    <img src={BASE_URL + toast.data.profileImage} style={{ width: '50px', height: '50px', minWidth: '50px', borderRadius: '10px' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', width: 'inherit' }}>
                        <p style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.3em', lineHeight: '1em', margin: 0, marginBottom: '3px' }}>{toast.title}</p>
                        <p style={{ color: '#00000099', fontSize: '1.1em', lineHeight: '1em', margin: 0, wordBreak: 'break-word' }}>{toast.body}</p>
                    </div>
                    <div onClick={closeToast} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                        <XIcon fill='black' size={23} />
                    </div>
                </div>
            </motion.div>
            )}
        </AnimatePresence>
    )
}



const ToastErrorMessage = () => {
    const [toast, setToast] = useState<string | null>()

    const showToast = (event) => {
        if (!event) return
        if (event.detail) {
            setToast(event.detail)
            setTimeout(() => {
                setToast(null)
            }, 3500)
        }
    }

    useEffect(() => {
        subscribe("show_toast", showToast);
        return () => {
            unsubscribe("show_toast", showToast);
        }
    }, [showToast, toast])

    if (!toast) return null

    return (
        <div className='toast' style={{ position: 'absolute', bottom: '80px', left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ background: 'linear-gradient(#db3b3b, #ba2525)', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 2px' }}>
                <p style={{ fontWeight: 'bold', color: 'white', fontSize: '1.3em', lineHeight: '1.2em', margin: '15px 20px', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>{toast}</p>
            </div>
        </div>
    )
}

const MessagesBadge = () => {
    const [num, setNum] = useState(0)

    const getData = async () => {
        const result = await PostData('getUnreadMessagesCount.php') as any
        setNum(result.num)
    }

    useEffect(() => {
        const id = setInterval(() => {
            getData()
        }, 2000)
        return () => {
            clearInterval(id)
        }
    }, [])

    if (!num) return null

    return (
        <div style={{ background: 'linear-gradient(#72B250, #509D2A)', position: 'absolute', borderRadius: '15px', top: 0, right: 0, height: '28px', padding: '5px', minWidth: '28px', margin: 0 }}>
            <p style={{ margin: 0, fontWeight: 'bold', color: 'white', fontSize: '1.2em', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>{num}</p>
        </div>
    )
}

export default Root