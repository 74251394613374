import React, { useEffect, useRef, useState } from 'react'
import { Form, redirect, useLoaderData, useNavigate, useNavigation, useSubmit } from "react-router-dom";
import { deleteEventPost, getEvent, getEventPosts, submitEventPost } from "../contacts";
import { ChevronRightIcon, PaperAirplaneIcon, PencilIcon, TrashIcon } from '@primer/octicons-react'
import { timeDifference } from '../utils.ts';
import StatusBar from '../StatusBar.tsx';
import { PostsProps } from '../Props.ts';
import EventButtons from '../EventButtons.tsx';
import { PostData } from '../PostData.ts';
import PostInput from '../PostInput.tsx';
import { BASE_URL } from '../variables.ts';

export async function loader({ params }) {
    const event = await getEvent(params.eventID);
    if (event.error_code === 'event_not_found') {
        return redirect('/events')
    }
    const posts = await getEventPosts(params.eventID)
    return { event, posts };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if (formData.get('delete_event_post')) {
        return deleteEventPost(formData.get('eventPostID'))
    }
    let updates = {}
    updates['status'] = formData.get("status")
    updates['file'] = formData.get("image")
    if (params.eventID) updates['eventID'] = params.eventID
    return submitEventPost(updates);
}

const Event: React.FC = () => {
    const { event, posts } = useLoaderData()
    const navigate = useNavigate()
    const [showEdit, setShowEdit] = useState(false)

    const goToUser = () => {
        navigate('/user/' + event.user_id)
    }

    const edit = () => {
        navigate('/event_creator/' + event.eventID)
    }

    const remove = async () => {
        await PostData('removeEvent.php', { eventID: event.eventID })
        navigate('/events')
    }

    const EditButtons = () => {
        if (event.owner && showEdit) return (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <button onClick={remove} style={{ flex: 1, height: '40px', position: 'relative', background: 'linear-gradient(#cc503d, #932919)', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Eliminar</p>
                </button>
                <button type='button' onClick={edit} style={{ flex: 1, height: '40px', position: 'relative', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <p style={{ margin: 0, fontSize: '1.1em', fontWeight: 'bold', color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)' }}>Editar</p>
                </button>
            </div>
        )
        return null
    }

    const EditIcon = () => {
        if (event.owner) return (
            <button onClick={() => setShowEdit((state) => !state)} style={{ position: 'absolute', top: '15px', right: '15px', display: 'flex', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', borderRadius: '10px' }}>
                <PencilIcon size={23} fill='white' />
            </button>
        )
        return null
    }

    const Info = () => {
        if (event.type !== 'birthday') {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill="#00000066" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Zm7-3.25v2.992l2.028.812a.75.75 0 0 1-.557 1.392l-2.5-1A.751.751 0 0 1 7 8.25v-3.5a.75.75 0 0 1 1.5 0Z"></path></svg>
                    <p style={{ margin: 0, color: '#00000066', fontWeight: 'bold', marginInline: '3px' }}>{getHours(event.date)}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill='#00000066' d="m12.596 11.596-3.535 3.536a1.5 1.5 0 0 1-2.122 0l-3.535-3.536a6.5 6.5 0 1 1 9.192-9.193 6.5 6.5 0 0 1 0 9.193Zm-1.06-8.132v-.001a5 5 0 1 0-7.072 7.072L8 14.07l3.536-3.534a5 5 0 0 0 0-7.072ZM8 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 9Z"></path></svg>
                    <p style={{ margin: 0, color: '#00000066', fontWeight: 'bold', marginInline: '3px' }}> {event.place} </p>
                </div>


            )
        }
        return null
    }

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar back />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '100px' }}>
                    <EditButtons />
                    <div key={event.eventID} style={{ borderBottomWidth: '1px', width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', paddingBottom: 0, position: 'relative' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'center' }}>
                            <img src={BASE_URL + event.eventImage} style={{ width: '80px', height: '80px', borderRadius: '40px', objectFit: 'cover', marginBottom: '10px' }} />
                            <EditIcon />
                            <p style={{ fontWeight: 'bolder', fontSize: '1.4em', lineHeight: '1em', margin: 0, marginBottom: '5px' }}>{event.name}</p>
                            <Info />
                            <p style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em', marginBlock: '5px' }}>{event.description}</p>
                            <div onClick={goToUser} style={{ display: 'flex', marginTop: '5px', alignItems: 'center', flex: 1, flexDirection: 'row'}}>
                                <p style={{ margin: 0, marginRight: '10px' }}>Creado por: </p>
                                <img src={BASE_URL + event.profileImage} style={{ width: '20px', height: '20px', borderRadius: '10px', objectFit: 'cover' }} />
                                <p style={{ margin: 0, marginLeft: '4px', lineHeight: '1em', fontWeight: '600' }}> {event.screenName}</p>
                            </div>
                        </div>

                    </div>
                    <EventButtons eventID={event.eventID} />
                    <div style={{ padding: '5px', paddingLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {event.users.map((user, index) => {
                            return <img key={user.user_id} src={BASE_URL + user.profileImage} style={{ width: '40px', height: '40px', borderRadius: '10px', marginLeft: '5px' }} />
                        })}
                        <p style={{ marginRight: '10px', marginLeft: '10px', fontWeight: 'bold' }}>{event.count} asistirán</p>
                    </div>
                    <PostsContainer posts={posts} />
                </div>

            </div>
        </div>
    );
}

const PostsContainer: React.FC<{ posts: PostsProps[] }> = ({ posts }) => {

    return (
        <>
            <PostInput />
            <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                {posts.map((item, index) => <Post key={item.postID} item={item} />)}
            </div>
        </>
    )
}

const Post: React.FC<{ item: PostsProps }> = ({ item }) => {
    const navigate = useNavigate();
    const submit = useSubmit()

    const goToEventPost = (event) => {
        event.stopPropagation();
        navigate('/event_post/' + item.eventPostID)
    }

    const goToUser = (event) => {
        event.stopPropagation();
        navigate('/user/' + item.user_id)
    }

    const deletePost = (event) => {
        event.stopPropagation();
        var form = new FormData()
        form.append('delete_event_post', 'true');
        form.append('eventPostID', item.eventPostID);
        submit(form, { encType: "multipart/form-data", method: 'post' })
    }


    return (
        <div onClick={goToEventPost} style={{ borderBottomWidth: '1px', borderBottomColor: '#dbdbdb', borderBottomStyle: 'solid' }}>
            <div key={item.postID} style={{ background: 'white', width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', position: 'relative' }}>
                <img onClick={goToUser} src={BASE_URL + item.profileImage} style={{ width: '60px', height: '60px', borderRadius: '15px', objectFit: 'cover' }} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                    <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.25em', lineHeight: '1em', margin: 0 }}>{item.screenName}</p>
                    <p dangerouslySetInnerHTML={{ __html: item.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1em', marginTop: '4px' }} />
                    {item.postImage && <img src={BASE_URL + item.postImage} style={{ height: '100px', borderRadius: '10px', marginTop: '10px' }} />}
                </div>
                <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                    <div style={{ alignSelf: 'center' }}><ChevronRightIcon fill={'#00000088'} size={30} /></div>
                </div>
                <p style={{ position: 'absolute', top: '4px', right: '10px', color: '#00000055', margin: 0, fontSize: '.85em' }}>{timeDifference(item.postedOn)}</p>
                {item.owner ? <div onClick={deletePost} style={{ position: 'absolute', bottom: 0, right: 0, background: '#00000011', borderTopLeftRadius: '20px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px', paddingLeft: '2px' }}>
                    <TrashIcon fill='#00000077' />
                </div> : null}
            </div>
            {item.comments && <div style={{ background: '#F0EFF3', paddingLeft: '50px', paddingTop: '5px', paddingBottom: '8px' }}>
                {item.comments.map((item, index) =>
                    <div key={item.commentID} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                        <img src={BASE_URL + item.profileImage} style={{ width: '30px', height: '30px', borderRadius: '15px', alignSelf: 'flex-start' }} />
                        <p style={{ margin: 0, marginLeft: '5px', fontSize: '1em', lineHeight: '1.1em' }}><span style={{ fontWeight: 'bold', margin: 0 }}>{item.screenName}</span> <span dangerouslySetInnerHTML={{ __html: item.comment }} /></p>
                    </div>
                )}
            </div>}
        </div>
    )
}

const getHours = (date: string) => {

    var fecha = new Date(date);

    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();

    hora = hora < 10 ? "0" + hora : hora;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    return hora + ":" + minutos
}

export default Event