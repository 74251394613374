import React from 'react'
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { deleteComment, getPost, submitComment } from "../contacts";
import StatusBar from '../StatusBar.tsx';
import PostInput from '../PostInput.tsx';
import CommentElement from '../CommentElement.tsx';
import { BASE_URL } from '../variables.ts';
import YouTubeEmbed from '../YoutubeEmbended.tsx';

export async function loader({ params }) {
    const post = await getPost(params.postID);
    if (post.error_code === 'post_not_found') {
        return redirect('/user')
    }
    return post;
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if (formData.get('delete_comment')) {
        return deleteComment(formData.get('commentID'))
    }
    return submitComment(params.postID, formData.get("status"), formData.get('friendID'));
}

const youtubeLinkRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;


function extractVideoId(url) {
    const match = url.match(youtubeLinkRegex);
    return match ? true : false;
  }

const Post: React.FC = () => {
    const { post, comments } = useLoaderData() as any || {};
    const navigate = useNavigate()

    const goToUser = () => {
        navigate('/user/' + post.postedBy)
    }

    const goToTablon = () => {
        navigate('/user/' + post.friendID)
    }

    const translate = () => {
        var paragraphs = document.getElementsByTagName("p");
        for (var i = 0; i < paragraphs.length; i++) {
            paragraphs[i].innerHTML = translate(paragraphs[i].innerHTML)
        }
    }

    const Info = () => {
        if (post.user_id == post.friendID) return (
            <div onClick={goToTablon} style={{ padding: '5px 18px', display: 'flex', alignItems: 'center', minHeight: '45px', background: '#fff' }}>
                <img src={BASE_URL + post.profileImage} style={{ width: '30px', height: '30px', borderRadius: '50px', objectFit: 'cover', minWidth: '30px' }} />
                <p style={{ margin: 0, fontSize: '0.9em', lineHeight: '0.9em', marginLeft: '10px', color: '#00000099', paddingBottom: '8px', paddingTop: '8px' }} >{post.screenName} ha comentado en su tablon:</p>
            </div>
        )
        return (
            <div onClick={goToTablon} style={{ padding: '5px 18px', display: 'flex', alignItems: 'center', minHeight: '45px', background: '#fff' }}>
                <img src={BASE_URL + post.profileImage} style={{ width: '30px', height: '30px', borderRadius: '50px', objectFit: 'cover', minWidth: '30px' }} />
                <img src={BASE_URL + post.friendImage} style={{ width: '30px', height: '30px', borderRadius: '50px', marginLeft: '-15px', objectFit: 'cover', minWidth: '30px' }} />
                <p style={{ margin: 0, fontSize: '0.9em', lineHeight: '0.9em', marginLeft: '10px', color: '#00000099', paddingBottom: '8px', paddingTop: '8px' }} >{post.screenName} ha comentado en el tablón de {post.friendName}:</p>
            </div>
        )
    }

    if (post.postImage) {
        return (
            <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <StatusBar back />
                <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '100px' }}>
                        <Info />
                        <div key={post.postID} style={{ borderBottomWidth: '1px', background: '#Fff', width: '100%', flexDirection: 'column', display: 'flex', paddingBottom: 0, alignItems: 'center' }}>
                            <img src={BASE_URL + post.postImage} style={{ maxWidth: '100%', maxHeight: '55vh', marginBottom: '10px', objectFit: 'contain', boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px' }} />
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%', padding: '3px 15px',  }}>
                                <img onClick={goToUser} src={BASE_URL + post.profileImage} style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit: 'cover' }} />
                                <div style={{ marginLeft: '10px' }}>
                                    <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.3em', lineHeight: '1.2em', margin: 0, }}>{post.screenName}</p>
                                    <p dangerouslySetInnerHTML={{ __html: post.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em' }} />
                                </div>
                            </div>
                        </div>
                        <PostInput user_id={post.postedBy} comment padding />
                        <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                            {comments.map((item, index) => <CommentElement item={item} />)}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    if(extractVideoId(post.status)) return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar back />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '100px' }}>
                    <Info />
                    <div key={post.postID} style={{ borderBottomWidth: '1px', width: '100%', flexDirection: 'column', display: 'flex', paddingBottom: 0, alignItems: 'center' }}>
                        <YouTubeEmbed fullscreen={true} url={post.status }/>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%', padding: '0 15px' }}>
                            <img onClick={goToUser} src={BASE_URL + post.profileImage} style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit: 'cover' }} />
                            <div style={{ marginLeft: '10px' }}>
                                <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.3em', lineHeight: '1.2em', margin: 0, }}>{post.screenName}</p>
                                <p dangerouslySetInnerHTML={{ __html: post.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em' }} />
                            </div>
                        </div>
                    </div>
                    <PostInput user_id={post.postedBy} comment padding />
                    <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                        {comments.map((item, index) => <CommentElement item={item} />)}
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%', background: '#fff' }}>
            <StatusBar back />
            <div style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: '100px' }}>
                    <Info />
                    <div key={post.postID} style={{ width: '100%', flexDirection: 'row', display: 'flex', padding: '15px', paddingRight: '5px', paddingBottom: 0 }}>
                        <img onClick={goToUser} src={BASE_URL + post.profileImage} style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit: 'cover' }} />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                            <p onClick={goToUser} style={{ fontWeight: 'bolder', fontSize: '1.3em', lineHeight: '1.3em', margin: 0, }}>{post.screenName}</p>
                            <p dangerouslySetInnerHTML={{ __html: post.status }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em' }} />
                        </div>
                    </div>
                    <PostInput user_id={post.postedBy} comment padding />
                    <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                        {comments.map((item, index) => <CommentElement item={item} />)}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Post