import { PostData } from "./PostData.ts";

export async function getNews() {
  const response = await PostData('getNews.php')
  return response
}

export async function getNotifications() {
  const result = await PostData('getNotifications.php')
  return result
}

export async function getEvents() {
  const result = await PostData('getEvents.php')
  return result
}

export async function getEvent(eventID) {
  const result = await PostData('getEvent.php', { eventID })
  return result
}

export async function getNotificationsCount() {
  const result = await PostData('getNotificationsCount.php')
  return result
}

export async function getPopularUsers() {
  const result = await PostData('getPopularUsers.php')
  return result
}

export async function getRecentMessages() {
  const result = await PostData("messages.php", { showMessage: true })
  return result
}

export async function getEventPosts(eventID) {
  const result = await PostData("getEventPosts.php", { eventID })
  return result
}

export async function getEventPost(eventPostID) {
  const result = await PostData("getEventPost.php", { eventPostID })
  return result
}

export async function getMessagesFrom(user_id) {
  const result = await PostData("messages.php", { showChatMessage: user_id })
  return result
}

export async function sendMessagesTo(user_id, message) {
  const result = await PostData("messages.php", { sendMessage: message, get_id: user_id })
  return result
}

export async function submitPost(updates) {
  const result = await PostData("addTweet.php", updates)
  return result;
}

export async function submitEventPost(updates) {
  const result = await PostData("addEventPost.php", updates)
  return result;
}

export async function submitEvent(updates) {
  const result = await PostData("addEvent.php", updates)
  return result;
}

export async function updateEvent(updates) {
  const result = await PostData("updateEvent.php", updates)
  return result;
}

export async function updateProfile(updates) {
  const result = await PostData("updateProfile.php", updates)
  return result;
}

export async function submitComment(postID, comment, friendID) {
  const result = await PostData("comment.php", { postID, comment, friendID })
  return result;
}

export async function deleteComment(commentID) {
  const result = await PostData("deleteComment.php", { commentID })
  return result;
}

export async function deletePost(postID) {
  const result = await PostData("deletePost.php", { postID })
  return result;
}

export async function deleteEventPost(eventPostID) {
  const result = await PostData("deleteEventPost.php", { eventPostID })
  return result;
}

export async function deleteEventComment(eventCommentID) {
  const result = await PostData("deleteEventComment.php", { eventCommentID})
  return result;
}

export async function submitEventComment(postEventID, comment, friendID) {
  const result = await PostData("postEventComment.php", { postEventID, comment, friendID })
  return result;
}

export async function search(text) {
  const result = await PostData('search.php', { search: text })
  return result
}

export async function searchFriends(text) {
  const result = await PostData('searchFriends.php', { search: text })
  return result
}

export async function searchEvent(text) {
  const result = await PostData('searchEvent.php', { search: text })
  return result
}

export async function getPost(id) {
  const result = await PostData('getPost.php', { postID: id })
  return result
}

export async function getPostByUserId(user_id, lastID) {
  var data = {};
  data['lastID'] = lastID;
  if (user_id) data = { user_id }
  const result = await PostData("fetchPostsByUser.php", data)
  return result
}

export async function getUserData(id, visita = false) {
  var data = {};
  if(id) data['user_id'] = id 
  if(visita) data['visitas'] = true
  const result = await PostData("getUserData.php", data)
  return result
}

