import React, { useEffect } from 'react'
import { Form, useLoaderData, useNavigate, useNavigation, useSubmit } from "react-router-dom";
import { getPopularUsers, search } from "../contacts";
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@primer/octicons-react'
import StatusBar from '../StatusBar.tsx';
import { BASE_URL } from '../variables.ts';

export async function loader({ request }) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q");
    console.log(q)
    var contacts;
    var suggest = false
    if(!q || q == ""){
        contacts = await getPopularUsers();
        suggest = true
    }else{
        contacts = await search(q);
    }
    return { contacts, q, suggest };
}

export async function action({ request, params }) {
    return true
}


const Search: React.FC = () => {
    const { contacts, q, suggest } = useLoaderData() as any;
    const navigation = useNavigation();
    const navigate = useNavigate()
    const submit = useSubmit();


    const searching =
        navigation.location &&
        new URLSearchParams(navigation.location.search).has(
            "q"
        );

    useEffect(() => {
        (document.getElementById('q') as HTMLInputElement).value = q;
    }, [q]);



    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <StatusBar text='Buscar' back />
            <Form id="search-form" role="search" style={{ padding: '15px', display: 'flex', width: '100%'}}>
                <input
                    id="q"
                    autoComplete="off"
                    aria-label="Search contacts"
                    placeholder="Escribe aquí..."
                    type="search"
                    name="q"
                    style={{ height: '45px', flex: 1 }}
                    className={searching ? "loading" : ""}
                    onChange={(event) => {
                        const isFirstSearch = q == null;
                        submit(event.currentTarget.form, {
                            replace: !isFirstSearch,
                        });
                    }}
                />
                <div id="search-spinner" aria-hidden hidden={!searching} />
                <div className="sr-only" aria-live="polite"></div>
            </Form>
            <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
                <div style={{ flexDirection: 'column', height: '200px', width: '100%' }}>
                    {suggest ? <p style={{marginLeft: '15px', color: 'grey', margin: 0, paddingLeft: '15px', marginBottom: '10px'}}>Populares</p> : null}
                    {contacts.map((item, index) => (
                        <div onClick={()=> navigate('/user/' + item.user_id)} key={item.user_id}  className='message-container'>
                            <img src={BASE_URL+item.profileImage} style={{ width: '45px', height: '45px', borderRadius: '30px', objectFit:'cover' }} />
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', justifyContent:'center' }}>
                                <p style={{  fontSize: '1.2em', lineHeight: '1em', margin: 0, fontWeight: '500' }}>{item.screenName}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
                                <div style={{ alignSelf: 'center' }}><ChevronRightIcon fill={'#00000033'} size={30} /></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}


export default Search