import { DeviceCameraIcon, PaperAirplaneIcon } from '@primer/octicons-react'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import './mentions_styles.css'
import { PostData } from './PostData.ts';
import { Form, useNavigation, useSubmit } from 'react-router-dom';
import { UserProps } from './Props.ts';
import Compressor from 'compressorjs';

const PostInput: React.FC<{ comment?: boolean, padding?: boolean, user_id?: number }> = ({ comment = false, padding = false, user_id }) => {
    const formRef = createRef<HTMLFormElement>()
    const [image, setImage] = useState<File | Blob | null>()
    const [value, setValue] = useState<string | number | readonly string[] | undefined>("")
    const { state, formData } = useNavigation()
    const submit = useSubmit()

    const onImageSelect = (event) => {
        const file = event.target.files[0];
        // Crear una instancia del compresor
        new Compressor(file, {
            maxWidth: 720,
            quality: 0.85, // Ajusta la calidad de la imagen comprimida
            success(result) {
                // `result` es la imagen comprimida
                setImage(result);
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    useEffect(() => {
        if (state === 'submitting') {
            if (formData?.get('status') || formData?.get('comment'))
                formRef.current?.reset()
            setValue('')
            setImage(null)
        }
    }, [state, formData])

    async function fetchUsers(query, callback) {
        if (!query) return
        const result = await PostData('search.php', { search: query }) as UserProps[]
        if (result) {
            callback(result.map(user => ({ display: user.screenName, id: user.user_id })))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (typeof value !== 'string') return
        var formData = new FormData()
        formData.append('friendID', user_id)
        if (image) {
            formData.append('image', image, image.name)
        }
        formData.append('status', value)
        submit(formData, {
            encType: "multipart/form-data",
            method: "post"
        })
    };

    const Label = () => {
        if (image) {
            return (
                <label htmlFor="fileInput" style={{
                    height: '50px', width: '50px', border: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    background: 'url(' + URL.createObjectURL(image) + ') center/cover',
                    lineHeight: '1.5',
                    marginRight: '15px',
                }} >
                </label>
            )
        }
        return (
            <label htmlFor="fileInput" style={{
                height: '50px', width: '50px', border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                lineHeight: '1.5',
                marginRight: '15px',
            }} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="26px" height="26px"><path fill='white' d="M15 3c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1 0-.55.45-1 1-1h4c.55 0 1 .45 1 1Zm-4.5 9c1.94 0 3.5-1.56 3.5-3.5S12.44 5 10.5 5 7 6.56 7 8.5 8.56 12 10.5 12ZM13 8.5c0 1.38-1.13 2.5-2.5 2.5S8 9.87 8 8.5 9.13 6 10.5 6 13 7.13 13 8.5ZM6 5V4H2v1Z"></path></svg>
            </label>
        )
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data" id='sendPost' method='post' name="sendPost" style={{ backgroundColor: '#fff', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px', display: 'flex', width: '100%', paddingTop: padding ? '10px' : 0 }}>
            {!comment && <>
                <Label />
                <input id='fileInput' type='file' accept="image/*" onChange={onImageSelect} name='image' style={{ marginRight: '15px', width: '50px', display: 'none' }} /></>}
            <MentionsInput
                className='mentionInput'
                placeholder="Escribe un comentario..."
                onChange={(event: any) => setValue(event.target.value)}
                value={value}
                style={defaultMentionStyle}
                singleLine={true}
            >
                <Mention
                    displayTransform={(id, display) => `@${display}`}
                    trigger="@"
                    data={fetchUsers}
                    appendSpaceOnAdd={true}
                    className="customMentionStyle"
                />
            </MentionsInput>
            <button type='submit' style={{ marginLeft: '15px', width: '50px', height: '50px', display:'flex', alignItems:'center', justifyContent:'center', padding:0 }} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="27px" height="27px" style={{marginLeft:'3px'}}><path fill='white' d="M.989 8 .064 2.68a1.342 1.342 0 0 1 1.85-1.462l13.402 5.744a1.13 1.13 0 0 1 0 2.076L1.913 14.782a1.343 1.343 0 0 1-1.85-1.463L.99 8Zm.603-5.288L2.38 7.25h4.87a.75.75 0 0 1 0 1.5H2.38l-.788 4.538L13.929 8Z"></path></svg>
            </button>
        </form >
    )
}

export const defaultMentionStyle = {
    control: {
        fontWeight: 'normal',
        minHeight: '50px',
        borderRadius: '30px',
        margin: 0,
        backgroundColor: '#00000013',
        lineHeight: '1.1em',
        flex: 1,
    },
    '&singleLine': {
        flex: 1,
        minHeight: '50px',
        fontSize: '1em',
        lineHeight: '1.1em',
        highlighter: {
            visibility: 'visible',
            lineHeight: '1em',
        },
        input: {
            minHeight: '50px',
            lineHeight: '1.1em',
        },
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            borderRadius: '10px',
            overflow: 'hidden',
            fontSize: '1em',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
        },
        item: {
            padding: '5px 15px',
            fontSize: '1em',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}

export default PostInput