import React, { useEffect, useState } from 'react'
import { PostData } from './PostData.ts'
import { useNavigate } from 'react-router-dom'
import { subscribe, unsubscribe } from './events.js';

const NotificationBadge: React.FC = () => {
    const navigate = useNavigate()
    const [notifications, setNotifications] = useState<{ num: number } | null>(null)

    const getCount = async () => {
        const result = await PostData('getNotificationsCount.php') as any
        setNotifications(result)
    }

    useEffect(()=>{
        getCount()
    },[])

    useEffect(() => {
        subscribe('notification', getCount)
        return () =>{
            unsubscribe('notification', getCount)
        }
    }, [getCount])   

    const goToNotifications = () => {
        navigate('/notifications')
    }

    if (!notifications || notifications.num == 0) {
        return (
            <div onClick={goToNotifications} style={{ width: '40px', height: '40px', background: '#00000037', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "inset rgba(0, 0, 0, 0.2) 0px 2px 4px" }}>
                <p style={{ color: '#ffffff', textShadow: '0px 1px 4px rgba(0, 0, 0, 0.36)', fontSize: '1.5em', fontWeight: 'bold', margin: 0 }}>0</p>
            </div>
        )
    }

    return (
        <div onClick={goToNotifications} style={{ width: '40px', height: '40px', background: '#87A946', borderRadius: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px" }}>
            <p style={{ color: 'white', textShadow: '0px 1px 4px rgba(0, 0, 0, 0.36)', fontSize: '1.5em', fontWeight: 'bold', margin: 0 }}>{notifications.num}</p>
        </div>
    )
}

export default NotificationBadge