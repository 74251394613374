import React, { useEffect } from 'react'
import { useNavigate, useSubmit } from 'react-router-dom'
import { timeDifference } from './utils.ts'
import { BASE_URL } from './variables.ts'
import { TrashIcon } from '@primer/octicons-react'

const CommentElement: React.FC<{ item: any }> = ({ item }) => {
    const navigate = useNavigate()
    const submit = useSubmit()

    useEffect(() => {
        const handleClick = (event) => {
            event.stopPropagation();
            const user_id = event.target.getAttribute('data-user');
            navigate('/user/' + user_id)
        };

        // Agrega event listener a todos los elementos con name="mention" cuando el componente se monta
        const mentionElements = document.getElementsByName('mention');
        mentionElements.forEach((element) => {
            element.addEventListener('click', handleClick);
        });

        // Elimina los event listeners al desmontar el componente para evitar memory leaks
        return () => {
            mentionElements.forEach((element) => {
                element.removeEventListener('click', handleClick);
            });
        };
    }, [])

    const deleteComment = (event) => {
        event.stopPropagation();
        var form = new FormData()
        form.append('delete_comment', 'true');
        form.append('commentID', item.commentID);
        submit(form, { encType: "multipart/form-data", method: 'post'})
    }


    return (
        <div key={item.commentID} className='message-container'>
            <img src={BASE_URL + item.profileImage} style={{ width: '60px', height: '60px', borderRadius: '15px', objectFit: 'cover' }} />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '5px', alignItems: 'flex-start' }}>
                <p onClick={() => navigate('/user/' + item.user_id)} style={{ fontWeight: 'bolder', fontSize: '1.2em', lineHeight: '1.4em', margin: 0, }}>{item.screenName}</p>
                <p dangerouslySetInnerHTML={{ __html: item.comment }} style={{ margin: 0, fontSize: '1.1em', lineHeight: '1.1em' }} />
            </div>
            <p style={{ position: 'absolute', top: '4px', right: '10px', color: '#00000055', margin: 0, fontSize: '.85em' }}>{timeDifference(item.createdOn)}</p>
            {item.owner ? <div onClick={deleteComment} style={{ position: 'absolute', bottom: 0, right: 0, background: '#00000011', borderTopLeftRadius: '20px', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px', paddingLeft: '2px' }}>
                <TrashIcon fill='#00000077' />
            </div> : null}
        </div>
    )
}

export default CommentElement