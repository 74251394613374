import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from "./routes/root.tsx";
import ErrorPage from "./error-page.tsx";
import News, {
    loader as newsLoader,
    action as newsAction
} from "./routes/home.tsx";
import Search,{
    loader as searchLoader,
    action as searchAction
} from "./routes/search.tsx";
import Post ,{
    loader as postLoader,
    action as postAction
} from "./routes/post.tsx";
import User ,{
    loader as userLoader,
    action as userAction
}from "./routes/user.tsx";
import Messages,{
    loader as messageLoader,
    action as messageAction
} from "./routes/messages.tsx";
import Chat,{
    loader as chatLoader,
    action as chatAction
} from "./routes/chat.tsx";
import Events,{
    loader as eventsLoader,
    action as eventsAction
} from "./routes/events.tsx";
import Notifications,{
    loader as notificationsLoader,
    action as notificationsAction
} from "./routes/notifications.tsx";
import EventCreator,{
    loader as eventCreatorLoader,
    action as eventCreatorAction
} from "./routes/eventCreator.tsx";
import Event,{
    loader as eventLoader,
    action as eventAction
} from "./routes/event.tsx";
import EventPost,{
    loader as eventPostLoader,
    action as eventPostAction
} from "./routes/event_post.tsx";
import Login ,{
    loader as loginLoader,
    action as loginAction
}from "./routes/login.tsx";
import EditContact,{
    loader as editProfileLoader,
    action as editProfileAction
} from "./routes/edit_profile.tsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true, 
                element: <Login />,
                loader: loginLoader,
                action: loginAction
            },
            {
                path: "home", 
                element: <News />,
                loader: newsLoader,
                action: newsAction
            },
            {
                path: "messages",
                element: <Messages />,
                loader: messageLoader,
                action: messageAction
            },
            {
                path: "chat/:userID",
                element: <Chat />,
                loader: chatLoader,
                action: chatAction
            },
            {
                path: "events",
                element: <Events />,
                loader: eventsLoader,
                action: eventsAction
            },
            {
                path: "event/:eventID",
                element: <Event />,
                loader: eventLoader,
                action: eventAction
            },
            {
                path: "event_post/:eventPostID",
                element: <EventPost />,
                loader: eventPostLoader,
                action: eventPostAction
            },
            {
                path: "event_creator",
                element: <EventCreator />,
                loader: eventCreatorLoader,
                action: eventCreatorAction
            },
            {
                path: "event_creator/:eventID",
                element: <EventCreator />,
                loader: eventCreatorLoader,
                action: eventCreatorAction
            },
            {
                path: "search",
                element: <Search />,
                loader: searchLoader,
                action: searchAction
            },
            {
                path: "post/:postID",
                element: <Post />,
                loader: postLoader,
                action: postAction
            },
            {
                path: "user/:userID",
                element: <User />,
                loader:userLoader,
                action: userAction
            },
            {
                path: "user",
                element: <User />,
                loader:userLoader,
                action: userAction
            },
            {
                path: "edit_profile",
                element: <EditContact />,
                loader: editProfileLoader,
                action: editProfileAction
            },
            {
                path: "notifications",
                element: <Notifications />,
                loader: notificationsLoader,
                action: notificationsAction
            },
        ],
    },
]);


const Navigator: React.FC = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Navigator