import React, { createRef, useEffect, useRef } from 'react'
import { Form, useLoaderData, useNavigate, useSubmit, useNavigation, useLocation } from "react-router-dom";
import { getMessagesFrom, getUserData, sendMessagesTo } from "../contacts";
import { CheckIcon, ChevronLeftIcon, PaperAirplaneIcon } from '@primer/octicons-react'
import { ChatMessageProp } from '../Props';
import '../Animations.css'
import '../chat.css'
import { BASE_URL } from '../variables.ts';

export async function loader({ params }) {
    const messages = await getMessagesFrom(params.userID);
    const user = await getUserData(params.userID)
    return { messages, user };
}

export async function action({ request, params }) {
    let formData = await request.formData();
    if (formData.get("refresh")) {
        const messages = await getMessagesFrom(params.userID);
        return { messages };
    }
    if (formData.get("message")) {
        const response = await sendMessagesTo(params.userID, formData.get("message"))
        return { response }
    }
    return null

}

const Chat: React.FC = () => {
    const { messages, user } = useLoaderData() as any || {};
    const submit = useSubmit()
    const navigate = useNavigate()
    const { state, formData } = useNavigation()
    const formRef = useRef()
    const inputRef = useRef<HTMLInputElement>()

    useEffect(() => {
        if (state === 'submitting' && formData?.get('message')) formRef.current?.reset()
    }, [state])

    useEffect(() => {
        const id = setInterval(() => {
            submit("refresh", { method: 'POST' })
        }, 2000)
        return () => {
            if (id) clearInterval(id)
        }
    }, [])

    const goToUser = () => {
        navigate('/user/' + user.user_id)
    }

    const sendBuzz = () => {
        submit({ message: ":vibrate", name: "send" },
            {
                method: "post",
                encType: "application/x-www-form-urlencoded",
            })
    }

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%', height: '60px', background: '#0076BC', alignItems: 'center', justifyContent: 'space-between', display: 'flex', paddingLeft: '15px', paddingRight: '15px' }}>
                <div onClick={() => { window.history.back() }}>
                    <ChevronLeftIcon fill={'white'} size={35} />
                </div>
                <p onClick={goToUser} style={{ color: 'white', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.36)', fontSize: '1.4em', fontWeight: 'bold', maxWidth: '75%', whiteSpace: 'nowrap', overflow: 'hidden', overflowWrap: 'break-word', textOverflow: 'ellipsis' }}>{user.screenName}</p>
                <img onClick={goToUser} src={BASE_URL + user.profileImage} style={{ width: '40px', height: '40px', borderRadius: '20px', objectFit: 'cover' }} />
            </div>
            <div className='chat-container'>
                
                    <div className='chat'>
                        {messages.map((item, index) => <ChatBubble key={item.messageID} item={item} />)}
                    </div>
            </div>

            <Form ref={formRef} name='send' method="post" id="post" encType="multipart/form-data" style={{ padding: '10px 15px', display: 'flex', width: '100%', borderTop: 'solid #dbdbdb 1px' }}>
                <div onClick={sendBuzz} style={{ width: '50px', height: '50px', padding: 0, minWidth: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <img src={require('../imgs/zumbido.png')} style={{ height: '33px', width: '33px' }} />
                </div>
                <input id='message' autoComplete="off" name='message' style={{ height: '50px', flex: 1, fontSize: '1.1em', width: 'inherit' }} placeholder='Escribe aquí...' />
                <button type='submit' style={{ marginLeft: '15px', width: '50px', height: '50px' }} >
                    <PaperAirplaneIcon size={25} />
                </button>
            </Form>
        </div>
    );
}


const ChatBubble: React.FC<{ item: ChatMessageProp }> = ({ item }) => {
    useEffect(() => {
        if (item.message === ":vibrate" && !item.status) {
            if ("vibrate" in navigator) {
                // vibration API supported
                navigator.vibrate(1000);
            }
        }
    }, [])

    if (item.message === ":vibrate" && item.owner) {
        return (
            <div data-time={getHours(item.messageOn)} className='msg sent buzz vibrate'><p style={{margin:"0 5px 0px 0"}}>Has enviado un zumbido </p><img style={{width:'30px', height:'30px'}} src={require('../imgs/zumbido.png')} /></div>
        )
    }

    if (item.message === ":vibrate" && !item.owner) {
        return (
            <div data-time={getHours(item.messageOn)} className='msg rcvd buzz vibrate'><img style={{width:'30px', height:'30px'}} src={require('../imgs/zumbido.png')} /><p style={{margin:"0 0px 0px 5px"}}>Te ha envidado un zumbido </p></div>

        )
    }

    if (item.owner) {
        return (
            <div data-time={getHours(item.messageOn)} className='msg sent'>{item.message}</div>
        )
    }
    return (
        <div key={item.messageID} data-time={getHours(item.messageOn)} className='msg rcvd'>{item.message}</div>
    )
}

const getHours = (date: string) => {

    var fecha = new Date(date);

    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();

    hora = hora < 10 ? "0" + hora : hora;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    return hora + ":" + minutos
}


export default Chat